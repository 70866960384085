function searchSubmit(form) {
  // build search url
  const searchFields = {};
  if (form.find('#id_people_0').is(':not(:checked)')) {
    searchFields.es = 't';
  }
  if (form.find('#id_people_1').is(':not(:checked)')) {
    searchFields.eh = 't';
  }
  if (form.find('#id_people_2').is(':not(:checked)')) {
    searchFields.emf = 't';
  }
  if (form.find('#id_people_3').is(':not(:checked)')) {
    searchFields.emm = 't';
  }
  if (form.find('#id_people_4').is(':not(:checked)')) {
    searchFields.emn = 't';
  }
  const ageFrom = form.find('#id_age_from').val();
  const ageTo = form.find('#id_age_to').val();
  if (ageFrom) {
    searchFields.af = ageFrom;
  }
  if (ageTo) {
    searchFields.at = ageTo;
  }
  const country = form.find('#id_country').val();
  if (country) {
    searchFields.co = country;
  }

  const state = form.find('#id_state').val();
  if (state) {
    searchFields.state = state;
  }

  const zipcode = form.find('#id_zipcode').val();
  if (zipcode) {
    searchFields.zip = zipcode;
  }

  const miles = form.find('#id_miles').val();
  if (miles) {
    searchFields.miles = miles;
  }

  const pigeonholes = $('#id_search_pigeonholes_ids').val();
  if (pigeonholes) {
    searchFields.p = pigeonholes;
  }
  $('.section-field').each(function eachField() {
    searchFields[`s${$(this).attr('sectionid')}`] = $(this).val();
  });
  $('.about-field').each(function eachField() {
    searchFields[`a${$(this).attr('aboutid')}`] = $(this).val();
  });

  let url = '';
  let first = true;
  // eslint-disable-next-line no-restricted-syntax
  for (const key in searchFields) {
    if (searchFields[key]) {
      if (first) {
        first = false;
        url += '?';
      } else {
        url += '&';
      }
      url += `${key}=${searchFields[key]}`;
    }
  }
  window.location = form.attr('action') + url;
}

function hideAddCriteriaLink(sectionId) {
  $(`#profile-criteria li a[data=${sectionId}]`).hide();
}

function showAddCriteriaLink(sectionId) {
  $(`#profile-criteria li a[data=${sectionId}]`).show();
}

function addSection(sectionId) {
  const section = $(`#section_wrapper_${sectionId}`);
  section.show();
  section.find('input').focus();
  hideAddCriteriaLink(sectionId);
}

function removeSection(sectionId) {
  const section = $(`#section_wrapper_${sectionId}`);
  section.hide();
  showAddCriteriaLink(sectionId);
}

function extractSectionId(nameOfSection) {
  return nameOfSection.replace('section_wrapper_', '');
}

function showZipcodeSearch() {
  $('.miles_within').show();
}

function clearZipcodeSearch() {
  $('#id_zipcode').val('');
  $('#id_miles').val('');
}

function hideZipcodeSearch() {
  clearZipcodeSearch();
  $('.miles_within').hide();
}

function showHideZipcodeOnState(stateCode) {
  if (stateCode === '') {
    hideZipcodeSearch();
  } else {
    showZipcodeSearch();
  }
}

function updateStateList(countryCode, stateCode) {
  const ajaxArgs = {
    dataType: 'html',
    data: { country: countryCode },
    url: $('#change_country_url').val(),
  };

  SG.apiPost(ajaxArgs).done((data) => {
    const formHtml = JSON.parse(data).postal_address;

    // extract the state name and options
    const options = $(formHtml).find('#id_state_or_province option');
    const label = $(formHtml).find('#id_state_or_province')
      .parent().siblings('label');
    if (options.length > 0) {
      $('#id_state').html(options);
      $('#id_state').prepend("<option value=''></option>");

      if (typeof stateCode !== 'undefined' && stateCode.length > 0) {
        $('#id_state').val(stateCode);
      } else {
        $('#id_state').val('');
      }

      $('#id_state_or_province_label').html(label.html());
      $('#id_state').parent().parent().show();
      showHideZipcodeOnState($('#id_state').val());
    } else {
      $('#id_state').parent().parent().hide();
      $('#id_state').val('');
      showHideZipcodeOnState(countryCode);
    }
  });
}

function initWidget() {
  $.widget('SG.tabbedMultiSelect', $.SG.tagCollector, {
    _create() {
      this.element.parent().find('.delete-toggle').on(
        'click',
        _.bind(this._clearElement, this),
      );
      $.SG.tagCollector.prototype._create.call(this);
    },

    _clearElement(e) {
      e.preventDefault();
      e.stopPropagation();
      const select = $(e.currentTarget).parents('.wrapper');
      const sectionId = extractSectionId(select.attr('id'));

      this.element.val('');
      this.element.parent().find('.tag').remove();
      this.element.parent().find('li').show();
      this.afterInputRender();

      removeSection(sectionId);
    },

    addTag(optionId) {
      if (this._checkLimit()) {
        return;
      }

      this._addTag(optionId);
      this._setInput();
    },

    augmentTag($newTag) {
      const optionId = $newTag.text().trim();
      const $listEl = this.element.parent().find(`li[optionid=${optionId}]`);

      $newTag.attr('optionid', optionId);
      $newTag.text($listEl.text().trim());
      $listEl.hide();
    },

    afterAdd() {
      if (
        typeof this.element.parent()
          .find('.drop-select').data('SGDropSelect') !== 'undefined'
      ) {
        this.element.parent().find('.drop-select')
          .data('SGDropSelect')._closeMenu();
      }
    },

    afterRemove($tagRemoved) {
      const optionid = $tagRemoved.attr('optionid');
      this.element.parent().find(`li[optionid=${optionid}]`).show();
    },

    _getValueFromTag($el) {
      return $el.attr('optionid');
    },

    afterInputRender() {
      const count = this._count();
      this.element.parent().find('.count').html(count);
    },
  });
}

export function init() {
  $(document).ready(() => {
    // Load only if part of this DOM element
    if (!$('#advanced-search-form').length) {
      return;
    }

    initWidget();

    $(document).on('change', '#id_country', (e) => {
      const countryCode = $(e.target).val();
      clearZipcodeSearch();
      updateStateList(countryCode);
    });

    $(document).on('change', '#id_state', (e) => {
      const stateCode = $(e.target).val();
      clearZipcodeSearch();
      showHideZipcodeOnState(stateCode);
    });

    const state = SG.parseQueryString('state');
    updateStateList($('#id_country').val(), state);

    $('#advanced-search-form').on('submit', function submit(e) {
      e.preventDefault();
      searchSubmit($(this));
    });

    // Show the section upon add criteria click
    $('#profile-criteria li a').on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      const sectionId = $(e.target).attr('data');
      addSection(sectionId);
    });

    $('#section_wrapper_pigeonholes li').on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      const optionId = $(e.target).attr('optionid');
      $('#id_search_pigeonholes_ids')
        .data('SGTabbedMultiSelect').addTag(optionId);
    });

    $('#section_wrapper_myidea_goodtime li').on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      const optionId = $(e.target).attr('optionid');
      $('#id_about_field_6').data('SGTabbedMultiSelect').addTag(optionId);
    });

    $('.drop-select > .delete-toggle').on('click', (e) => {
      // This is used to remove about sections from advanced search
      e.preventDefault();
      e.stopPropagation();
      // enable the right section
      const select = $(e.currentTarget).parents('.wrapper');
      const sectionId = extractSectionId(select.attr('id'));
      select.find('.clear-select').click();
      removeSection(sectionId);
    });

    $('.content-box .delete-toggle').on('click', (e) => {
      // This is used to remove free text sections from advanced search
      e.preventDefault();
      e.stopPropagation();
      // enable the right section
      const section = $(e.currentTarget).parents('.content-box');
      const sectionId = extractSectionId(section.attr('id'));
      section.find('.section-field').val('');
      section.find('.tag').remove();
      removeSection(sectionId);
    });

    // Create a fancybox for advanced search form
    $('#advanced_search_toggle, #advanced_search_toggle_mobile').fancybox({
      afterLoad() {
        // eslint-disable-next-line max-len
        this.title = `<a href="javascript:;" class="icon-close" onclick="$.fancybox.close();"></a><span>${this.title}</span><br clear="all">`;
      },
      closeBtn: false,
      helpers: {
        title: {
          type: 'inside',
          position: 'top',
        },
      },
      title: 'Advanced Search',
      padding: 0,
      margin: 10,
      autoSize: true,
      maxWidth: 500,
      autoWidth: true,
      height: 'auto',
      scrolling: 'visible',
    });

    $('#advanced-search-form').on('click', '#clear-search-button', () => {
      $('#advanced-search-form input')
        .not('input[name=csrfmiddlewaretoken]').val('');
      $('#advanced-search-form input[type=checkbox]').prop('checked', true);
    });

    $('.section-input').tagCollector({ itemTag: 'span' });

    $('#id_search_pigeonholes_ids').tabbedMultiSelect({
      itemTag: 'span',
      limit: 3,
    });
    $('#id_about_field_6').tabbedMultiSelect({
      itemTag: 'span',
      limit: 3,
    });
  });
}
export default init;
