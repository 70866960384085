import 'core-js';
import 'regenerator-runtime/runtime';

import * as ads from 'legacy/ads';
import * as feeds from 'legacy/feeds';
import * as notifications from 'legacy/notifications';
import * as search from 'legacy/search';
import * as tagging from 'legacy/tagging';
import * as testimonials from 'legacy/testimonials';
import * as tipping from 'legacy/tipping';
import * as video from 'legacy/video';
import * as voting from 'legacy/voting';

// Init modules / DOM listeners
ads.init();
feeds.init();
notifications.init();
search.init();
tagging.init();
testimonials.init();
tipping.init();
video.init();
voting.init();
