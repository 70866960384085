import Handlebars from 'handlebars';

const template = $('#tipping_template').length > 0
  ? Handlebars.compile($('#tipping_template').html()) : '';

export default Marionette.ItemView.extend({
  template,

  events: {
    'click .tip-amounts button:not(.send-custom-amount)':
      'clickAmountButtonEvent',
    'click .send-custom-amount': 'clickCustomAmountEvent',
    'click .btn-confirm-tip': 'clickTipButtonEvent',
    'click .icon-arrow-left': 'clickBackEvent',
    'click .icon-close': 'clickCloseEvent',
    'change .custom-tip-amount-value': 'changeCustomTipAmountEvent',
    'keyup  .custom-tip-amount-value': 'changeCustomTipAmountEvent',
    'click .send-add-more': 'clickAddMoreEvent',
    'focus .tip-message': 'focusTipMessageEvent',
    'focusout .tip-message': 'focusOutTipMessageEvent',
    'keypress .tip-message': 'textChangedEvent',
    'paste .tip-message': 'textChangedEvent',
    'click .tip-privacy li': 'privacyChangedEvent',
  },

  initialize(options) {
    this.recipients = options.recipients;
    this.contentTypeId = options.contentTypeId;
    this.objectId = options.objectId;
    this.public = true;
    this.refreshBalance();
  },

  refreshBalance() {
    const ajaxArgs = {
      url: '/api/balance/',
    };

    const self = this;

    SG.apiGet(ajaxArgs).then((data) => {
      self.renderBalance(data.amount);
    });
  },

  renderBalance(amount) {
    const floatAmount = parseFloat(amount);
    this.$el.find('.current-balance-amount')
      .html(this.humanizeBalance(floatAmount));
    $('body').attr('sg-user_balance', floatAmount);
    this.toggleCTA(floatAmount);
    this.toggleButtons(floatAmount);
  },

  serializeData() {
    const balance = this.userBalance();
    const templateData = {
      balance: this.humanizeBalance(balance),
      content_type_id: this.contentTypeId,
      object_id: this.objectId,
      recipients: this.formatRecipients(this.recipients),
      nsf: balance < 1,
      disable1: balance < 1,
      disable2: balance < 2,
      disable5: balance < 5,
      disable10: balance < 10,
      disable15: balance < 15,
      public: this.public,
    };
    return templateData;
  },

  toggleButtons(balance) {
    this.$el.find('.tip-amounts button').each(function eachButton() {
      const amount = $(this).data('amount');
      if (amount === undefined) {
        return;
      }
      const hasAmount = parseFloat(amount) <= balance;
      $(this).prop('disabled', !hasAmount);
    });
  },

  toggleCTA(balance) {
    const disabled = balance < 1;
    this.$el.find('.btn-confirm-tip').prop('disabled', disabled);
  },

  userBalance() {
    return parseFloat($('body').attr('sg-user_balance'));
  },

  humanizeBalance(balance) {
    const fixed = balance.toFixed(2);
    return balance >= 0 ? `$${fixed}` : `- $${Math.abs(fixed)}`;
  },

  formatRecipients(recipients) {
    if (!recipients) {
      return '';
    }

    const _recipients = recipients.split(',');
    _recipients.sort();

    if (_recipients.length === 1) {
      return _recipients[0];
    }

    if (_recipients.length === 2) {
      return `${_recipients[0]} & ${_recipients[1]}`;
    }

    return `${_recipients[0]} & ${_recipients.length - 1} Others`;
  },

  onRender() {
    const balance = this.userBalance();
    this.toggleButtons(balance);

    if (balance >= 1) {
      this.$el.find('.btn-confirm-tip').data('amount', '1.00');
    }
  },

  clickCloseEvent(e) {
    e.preventDefault();
    this.trigger('close');
  },

  clickAmountButtonEvent(e) {
    this.$el.find('.tip-amounts button').removeClass('selected-amount');
    $(e.target).addClass('selected-amount');
    this.$el.find('.btn-confirm-tip').data(
      'amount',
      $(e.target).data('amount'),
    );
  },

  clickCustomAmountEvent(e) {
    e.preventDefault();
    this.showCustomAmountState();
  },

  showCustomAmountState() {
    this.$el.find('.icon-arrow-left').show();
    this.$el.find('h2 span.leave-heading').hide();
    this.$el.find('h2 span.amount-heading').show();
    this.$el.find('.tip-amounts').hide();
    this.$el.find('.tip-custom-amount').show();
    this.$el.find('.custom-tip-amount-value').val('');
    this.$el.find('.btn-confirm-tip').prop('disabled', true);
    this.$el.find('.btn-confirm-tip').data('amount', '0.00');
    this.$el.find('.custom-tip-amount-value').attr('placeholder', '0.00');
  },

  clickBackEvent(e) {
    e.preventDefault();
    this.showPresetAmountsState();
  },

  showPresetAmountsState() {
    this.$el.find('h2 span.leave-heading').show();
    this.$el.find('h2 span.amount-heading').hide();
    this.$el.find('.tip-amounts').show();
    this.$el.find('.tip-custom-amount').hide();
    this.$el.find('.icon-arrow-left').hide();
    const selectedAmount = this.$el.find('.selected-amount').data('amount');
    this.$el.find('.btn-confirm-tip').data('amount', selectedAmount);
    this.toggleButtons(this.userBalance());
    this.toggleCTA(this.userBalance());
  },

  changeCustomTipAmountEvent(e) {
    const amount = $(e.target).val();
    const validAmount = !!amount.match(/^\d{0,4}(?:\.\d{1,3})?$/);
    const hasSufficientBalance = (
      validAmount && parseFloat(amount) <= this.userBalance()
    );
    const lessThanMinimum = parseFloat(amount) < 1;
    const disableCTA = !validAmount || !hasSufficientBalance || lessThanMinimum;
    this.$el.find('.btn-confirm-tip').prop('disabled', disableCTA);
    this.$el.find('.btn-confirm-tip').data('amount', amount);
  },

  clickAddMoreEvent(e) {
    e.preventDefault();
    this.trigger('add_more');
  },

  focusTipMessageEvent() {
    this.$el.find('.tip-message').addClass('active');
  },

  focusOutTipMessageEvent() {
    const message = this.$el.find('.tip-message').text().trim();
    if (message === '') {
      this.$el.find('.tip-message').removeClass('active');
    }
  },

  textChangedEvent(e) {
    if (e.which === 13) {
      e.preventDefault();
    }
  },

  privacyChangedEvent(e) {
    this.public = $(e.currentTarget).data('public');
    if (this.public) {
      this.$el.find('li[data-public="true"]').addClass('selected');
      this.$el.find('li[data-public="false"]').removeClass('selected');
    } else {
      this.$el.find('li[data-public="true"]').removeClass('selected');
      this.$el.find('li[data-public="false"]').addClass('selected');
    }
  },

  clickTipButtonEvent(e) {
    let message = this.$el.find('.tip-message').text().trim();
    const amount = $(e.target).data('amount');
    const contentTypeId = $(e.target).data('content_type_id');
    const objectId = $(e.target).data('object_id');
    const postData = {
      amount,
      public: this.public,
      text: message,
    };
    const apiv3 = $('body').data('apiv3');
    const ajaxArgs = {
      url: `${apiv3}objects/${contentTypeId}/${objectId}/tips/`,
      data: JSON.stringify(postData),
      dataType: 'json',
      contentType: 'application/json; charset=UTF-8',
      xhrFields: {
        withCredentials: true,
      },
    };
    const self = this;
    SG.apiPost(ajaxArgs)
      .done(() => {
        const recipients = self.formatRecipients(self.recipients);
        message = SG.userLang() === 'es'
          ? `¡Mandaste propina de $${postData.amount} a ${recipients}!`
          : `You Tipped ${recipients} $${postData.amount}!`;
        SG.userSuccess(message);
        self.trigger('complete');
        self.refreshBalance();
      })
      .fail(() => {
        message = SG.userLang() === 'es'
          ? 'La propina no pudo ser enviada' : 'Error sending tip';
        SG.userError(message);
      });
  },
});
