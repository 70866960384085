export default Backbone.Model.extend({
  /**
   Sync the model data to the server.

   Note: overrides Backbone's default sync method.
   */
  sync(method) {
    let request;

    switch (method) {
      case 'create':
      case 'update':
        request = this.sendRequest();
        break;
      case 'delete':
      case 'read':
      default:
        request = $.Deferred();
        request.reject();
    }

    return request;
  },

  /**
   Send the request to create or edit a comment to the server.
   */
  sendRequest() {
    const data = this.assembleFormData();

    return $.ajax({
      type: 'POST',
      url: '/api/say_thanks/',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(data),
      xhrFields: {
        withCredentials: true,
      },
    });
  },

  /**
   Assemble the form data into the object sent to server.
   */
  assembleFormData() {
    const data = {
      text: this.attributes.text,
      transaction: this.attributes.transaction_id,
    };

    return data;
  },
});
