import Handlebars from 'handlebars';
/**
   Allows the user to perform a search using an overlaid
   modal dialog.
 */
export default Backbone.View.extend({
  initialize(options) {
    this.options = options;
    this.initializeUI();
  },

  /**
     Shows the search modal dialog.

     @param {String} key - a key the user has pressed which triggered the open,
     and will go into the text input as first character.
   */
  show(key) {
    const modalDeferred = SG.initModal(this.$el);

    this.$el.find('input[name="s"]').focus();
    this.$el.find('input[name="s"]').prop('value', key);

    const self = this;
    modalDeferred.done(() => {
      $('body').append(self.$el);
    });

    return modalDeferred;
  },

  initializeUI() {
    const viewingGroup = !!this.$el.data('groupName');
    const viewingGroupInline = !!$('#search-form-inline').data('groupName');

    if (viewingGroup) {
      this.initializeGroupUI();
    }

    if (viewingGroupInline) {
      this.initializeGroupInlineUI();
    }

    if (!viewingGroup && !viewingGroupInline) {
      this.initializeAutocompleteUI();
    }
  },

  /**
     Intializes the group specific UI features.

     Note: group specific feature includes a slider that lets the user chose
     between search just this group, or the whole site.
   */
  initializeGroupUI() {
    const $searchSlider = $('#search-slider');
    const groupName = $('#search-form').data('groupName');
    const $gInput = $(`<input name="g" type="hidden" value="${groupName}" />`);
    const $category = this.$el.find('input[name="category"]');

    $searchSlider.append($gInput);
    $searchSlider.noUiSlider({
      range: [0, 1],
      start: 0,
      step: 1,
      orientation: 'vertical',
      handles: 1,

      slide() {
        if ($searchSlider.val() === 0) {
          $searchSlider.append($gInput);
          $category.val('threads');
        } else {
          $gInput.remove();
          $category.val('');
        }
      },
    });
  },

  initializeGroupInlineUI() {
    const $searchSlider = $('#search-slider-inline');
    const groupName = $('#search-form-inline').data('groupName');
    const $gInput = $(`<input name="g" type="hidden" value="${groupName}" />`);
    const $category = $('#search-form-inline input[name="category"]');

    $searchSlider.append($gInput);
    $searchSlider.noUiSlider({
      range: [0, 1],
      start: 0,
      step: 1,
      orientation: 'vertical',
      handles: 1,

      slide() {
        if ($searchSlider.val() === 0) {
          $searchSlider.append($gInput);
          $category.val('threads');
        } else {
          $gInput.remove();
          $category.val('');
        }
      },
    });
  },

  /**
     Initializes the member name autocomplete feature.

     Note: this shows any member names matching the search text below the search
     input, with the members profile picture.
   */
  initializeAutocompleteUI() {
    SG.$elements.$searchInput.autocomplete({
      source(request, response) {
        if (SG.$elements.$searchInput.val().length < 2) {
          return;
        }

        const ajaxArgs = {
          url: '/quick_member_name_search/',
          data: {
            term: request.term,
          },
        };

        const gettingRequest = SG.apiGet(ajaxArgs);

        gettingRequest.always((data) => {
          response(data);
        });
      },
      appendTo: '.search-autocomplete',
      minLength: 1,
      select(e, ui) {
        const username = ui.item.value;
        $(this).val(username).blur();
      },
      change() {},
      close() {},
      search() {},
      messages: {
        noResults: '',
        results() {},
      },
    }).data('uiAutocomplete')._renderItem = this.renderAutocompleteItem;
  },

  /**
     Renders an autocompleted member name/profile photo item.
   */
  renderAutocompleteItem(ul, item) {
    const $source = $('#img_template');
    const template = Handlebars.compile($source.html());
    const data = {
      src: item.cover_photo.urls['230x230'],
      srcretina: item.cover_photo.urls['460x460'],
    };
    const templateWithData = template(data);
    const innerHtml = ''
        + `<a href="${item.absolute_url}">${
          templateWithData
        }<div class="name-bar">${item.label}</div>`
        + '</a>';

    return $('<li></li>')
      .data('item.autocomplete', item)
      .on('click', () => {
        window.location = item.absolute_url;
      })
      .append(innerHtml)
      .appendTo(ul);
  },
});
