import Handlebars from 'handlebars';

import AffiliateRegistrationDialogView from './AffiliateRegistrationDialogView';
import CreateThankYouMessageView from './CreateThankYouMessageView';

import Tipping from 'legacy/tipping/Tipping';

const Notifications = {
  /**
  User clicks the cash out button on the notifications page.
  * */
  clickCashOutEvent(e) {
    const unregisteredAffiliate = $(e.target).data('unregistered-affiliate');
    const amount = $(e.target).data('amount');
    const purchased = $(e.target).data('purchased');

    if (unregisteredAffiliate) {
      this.showAffiliateRegistrationPopup(amount, purchased);
    } else {
      const paymentPreference = $(e.target).data('payment-preference');
      const paymentPreferenceDetail = $(e.target)
        .data('payment-preference-detail');
      const paymentPreferenceUrl = $(e.target).data('payment-preference-url');
      const mustUpdatePayoutMethod = $(e.target)
        .data('must-update-payment-preference');
      const hasOpenCashOutRequest = $(e.target).data('has-open');
      this.showCashOutPopup(
        amount,
        purchased,
        paymentPreference,
        paymentPreferenceDetail,
        paymentPreferenceUrl,
        mustUpdatePayoutMethod,
        hasOpenCashOutRequest,
      );
    }
  },

  showAffiliateRegistrationPopup(amount, purchased) {
    const $content = $(
      '<div class="fancy-affiliate-registration-dialog"></div>',
    );

    const view = new AffiliateRegistrationDialogView({
      el: $content,
      amount,
      purchased,
    });
    view.listenTo(view, 'close', () => {
      view.destroy();
      $.fancybox.close();
    });
    view.listenTo(view, 'complete', () => {
      const message = SG.userLang() === 'es'
        ? '¡Solicitud enviado!' : 'Cash out request sent!';
      SG.userSuccess(message);
      setTimeout(() => {
        window.location.href = '/notifications/';
      }, 1000);
    });
    view.render();

    $.fancybox({
      autoDimensions: false,
      autoSize: false,
      type: 'inline',
      content: $content,
      fitToView: false,
      closeClick: false,
      openEffect: 'none',
      closeEffect: 'none',
      closeBtn: false,
      padding: 0,
      margin: 10,
      width: SG.isPhone() ? '100%' : 475,
    });
  },

  showCashOutPopup(
    amount,
    purchased,
    paymentPreference,
    paymentPreferenceDetail,
    paymentPreferenceUrl,
    mustUpdatePayoutMethod,
    hasOpenCashOutRequest,
  ) {
    const source = $('#notifications-cash-out-template').html();
    const template = Handlebars.compile(source);
    const templateData = {
      amount,
      purchased,
      has_purchased: purchased && purchased !== '0.00',
      must_update_payout_method: mustUpdatePayoutMethod,
      payment_preference: paymentPreference,
      payment_preference_detail: paymentPreferenceDetail,
      payment_preference_url: paymentPreferenceUrl,
      has_open: hasOpenCashOutRequest,
      disable: mustUpdatePayoutMethod || hasOpenCashOutRequest,
    };

    $.fancybox({
      autoDimensions: false,
      type: 'inline',
      content: template(templateData),
      fitToView: true,
      closeClick: false,
      openEffect: 'none',
      closeEffect: 'none',
      closeBtn: false,
      padding: 0,
      margin: 10,
      width: 760,
    });

    $('.btn-cash-out').on(
      'click',
      _.bind(this.confirmCashOutClickEvent, this),
    );
  },

  /**
     User clicked cash out button on the cash out popup.
   */
  confirmCashOutClickEvent(e) {
    e.preventDefault();
    const amount = $(e.target).data('amount')
      || $(e.target).parent().data('amount');
    $('body').spinner(SG.spinnerSettings);
    const request = this.sendCashOutRequest(amount);

    request.fail(() => {
      const message = SG.userLang() === 'es'
        ? 'No se pudo solicitar el retiro' : 'Error cashing out';
      SG.userError(message);
      $('body').clearSpinner();
    });

    request.done(() => {
      $.fancybox.close();
      $('body').clearSpinner();
      const message = SG.userLang() === 'es'
        ? '\u00A1Solicitud enviada existosamente!'
        : 'Successfully cashed out!';
      SG.userMessage(message);
      setTimeout(() => {
        window.location = '/notifications/';
      }, 2000);
    });
  },

  sendCashOutRequest(amount) {
    if (!amount) {
      return $.Deferred().reject();
    }

    return SG.apiPost({
      url: '/api/cashout/',
      contentType: 'application/json; charset=UTF-8',
      data: JSON.stringify({
        amount,
      }),
    });
  },

  /**
   User clicks the add money button on the notifications page.
  */
  clickAddMoneyEvent() {
    Tipping.showAddMoneyPopup();
  },

  /**
  User focuses on say thanks input.
  */
  focusThanksEvent(e) {
    e.preventDefault();
    const $thanksForm = $(e.target).closest('form');
    if (!$thanksForm.data('view')) {
      $thanksForm.data(
        'view',
        new CreateThankYouMessageView({ el: $thanksForm }),
      );
    }
    $thanksForm.data('view').activate();
  },

  shareAffiliateLinkFacebook(e) {
    e.preventDefault();
    const appID = $(e.target).data('facebook-id');
    window.FB.init({ appId: appID, status: true, cookie: true });
    window.FB.ui({
      method: 'share',
      href: $(e.target).data('affiliate-link'),
    }, () => {});
  },

  shareAffiliateLinkTwitter(e) {
    e.preventDefault();
    const affiliateURL = $(e.target).data('affiliate-link');
    const twitterURL = (
      `https://twitter.com/intent/tweet?text=Join+SG+Now! ${affiliateURL}`
    );

    const width = 575;
    const height = 400;
    const left = ($(window).width() - width) / 2;
    const top = ($(window).height() - height) / 2;
    const opts = 'status=1'
                 + `,width=${width
                 },height=${height
                 },top=${top
                 },left=${left}`;
    window.open(twitterURL, 'twitter', opts);
    return false;
  },
};
export default Notifications;
