import Tipping from './Tipping';

import './tipping.less';

export function init() {
  $(document).ready(() => {
    // Tip button binding
    $('body').on('click', '.tip', (e) => {
      e.preventDefault();
      if (!SG.user.logged_in_user_id || SG.user.logged_in_user_id === 'None') {
        window.location.href = '/join/';
      } else {
        const contentTypeId = $(e.target).closest('a,button')
          .data('content_type_id');
        const objectId = $(e.target).closest('a,button').data('object_id');
        const recipients = $(e.target).closest('a,button')
          .data('tip-recipients');
        const galleryId = $(e.target).closest('a,button').data('gallery-id');
        Tipping.showTipDialog(contentTypeId, objectId, recipients, galleryId);
      }
    });

    // Event handler for deleting a tip post
    $('body').on(
      'click',
      '.tip-delete',
      _.bind(Tipping.deleteTipPostEvent, Tipping),
    );
  });

  $(document).ready(() => {
    if (!window.location.hash) return;

    let groups = /^#tip-([0-9]+)-([0-9]+)$/.exec(window.location.hash);
    if (groups) {
      const contentTypeId = groups[1];
      const objectId = groups[2];
      SG.apiGet({
        url: '/api/tip_recipients/?content_type_id='
          + `${contentTypeId}&object_id=${objectId}`,
      }).then((recipients) => {
        Tipping.showTipDialog(contentTypeId, objectId, recipients);
      });
      return;
    }
    groups = /^#gallery-tip-([0-9]+)-([0-9]+)-([0-9]+)$/.exec(
      window.location.hash,
    );
    if (groups) {
      const [albumId, contentTypeId, objectId] = groups;

      SG.gallery = new SG.Gallery();

      SG.gallery.init({
        $thumbContainer: $('.album-container'),
        album_id: albumId,
        sgAlbumId: objectId,
        origin: $('.album-container'),
      });

      SG.gallery.load();

      SG.apiGet({
        url: '/api/tip_recipients/?content_type_id='
          + `${contentTypeId}&object_id=${objectId}`,
      }).then((recipients) => {
        Tipping.showTipDialog(
          contentTypeId,
          objectId,
          recipients,
          albumId,
        );
      });
    }
  });
}

export default init;
