import ThankYouMessage from './ThankYouMessage';

export default EditContentView.extend({
  spinnerOnSave: true,

  events: {
    'blur [contenteditable]': 'blurEvent',
    'keypress [contenteditable]': 'textChangedEvent',
    'paste [contenteditable]': 'textChangedEvent',
  },

  /**
     Initializes the view.
   */
  initialize(options) {
    this.model = new ThankYouMessage({
      transaction_id: this.$el.data('transaction-id'),
    });
    EditContentView.prototype.initialize.apply(this, [options]);
    this.extendEvents(EditContentView);
  },

  /**
     Activate the say thanks form.
   */
  activate() {
    this.$el.addClass('active');
    SG.reLayout();
  },

  /**
     Deactivates the say thanks text box, returning it to its default state.
   */
  deactivate() {
    this.$el.removeClass('active');
  },

  /**
     Blur event when the user clicks outside of the editor.
  */
  blurEvent() {
    this.syncFormToModel(this.$el, this.model);
    if (!this.model.get('text')) {
      this.deactivate();
    }
    SG.reLayout();
  },

  /**
     Event handler when the user types or pastes text into the editable area.

     @param {object} e - JQuery event data.
  */
  textChangedEvent(e) {
    if (SG.settings.isotope) {
      if (
        $(e.target).data('lastHeight') !== undefined
        && $(e.target).data('lastHeight') !== $(e.target).height()
      ) {
        SG.reLayout();
      }
      if (e.type === 'paste') {
        // Needs a timer otherwise it fires BEFORE the text is in the box.
        setTimeout(SG.reLayout, 10);
      }
      $(e.target).data('lastHeight', $(e.target).height());
    }

    if (e.which === 13) {
      e.preventDefault();
      this.$el.find('button[type="submit"]').trigger('click');
    }
  },

  /**
     Syncs the current form values to the model.
   */
  syncFormToModel($form, model) {
    model.set('action', $form.attr('action'));
    model.set('text', this.$el.find('[contenteditable]').text());
  },

  /**
     Refreshes the page after successful save.
   */
  refreshPage() {
    this.$el.find('p').val('');
    this.$el.find('[contenteditable]').text('');

    SG.reLayout();

    this.$el.find('.say-thanks').hide();

    this.deactivate(this.$el);
  },
});
