import Handlebars from 'handlebars';

import Testimonial from './Testimonial';

export default Backbone.View.extend({
  config: {
    editorContainer: '#testimonial_data',
    geometry: '508',
    msgStillProcessing: (
      'Your photo is still being processed. Please try again.'
    ),
    msgSaveSuccess: 'Post successfully saved.',
    pollUploadJobStatusInterval: 2500,
    savedDraftPrefix: 'post',
    textTarget: 'textarea',
    upload_job_numbers: null,

  },

  /**
     Event handlers for the view.
   */
  events: {
    submit: 'formSubmitEvent',
    'focus #testimonial_data': 'focusEvent',
    'blur #testimonial_data': 'blurEvent',
  },

  /**
     Initializes the testimonials module.
   */
  initialize() {
    this.config.context = this.$el;
    this.collection().on(
      'fpfiles_successful',
      this.fpfilesSuccessfulHandler,
      this,
    );
    this.collection().on(
      'fpfiles_failed',
      this.fpfilesFailedHandler,
      this,
    );

    this.bindEvents();
  },

  /**
     Returns the model for the item being edited.
  */
  model() {
    const $el = this.$el ? this.$el : this.config.context;

    let model = $el.data('model');

    if (!model) {
      model = new Testimonial({});
      $el.data('model', model);
    }

    return model;
  },

  /**
     Returns the photo collection for the form.
   */
  collection() {
    const $el = this.$el ? this.$el : this.config.context;
    let collection = $el.data('photo-collection');

    if (!collection) {
      collection = new PhotoCollection([], {
        album: this.config.Album,
        albumType: $el.attr('data-album-type'),
      });
      $el.data('photo-collection', collection);
    }

    return collection;
  },

  /**
     Binds the event handlers for the testimonial form.
  */
  bindEvents() {
    $('.create_testimonial_buttons a')
      .click(_.bind(this.tabClickedEvent, this)); // Tabs across the top
    $('body').on(
      'keypress paste',
      '#testimonial_data',
      _.bind(this.textChangedEvent, this),
    );
    $('#modal-close').on(
      'click',
      _.bind(this.modalClosedEvent, this),
    ); // Closed the select file modal dialog
  },

  /**
     Loads the default state of the testimonial form.

     Note: this is the one the user sees when the page first loads, before
     anything has been clicked or any data has been entered.
  */
  loadDefaultState() {
    $('#new_testimonial').find('[contenteditable]').text('');
    $('#new_testimonial').find('textarea').val('');
    $('#new_testimonial').removeClass('active');
    const $submit = $('#new_testimonial').find('.comment-count');
    $submit.text($submit.data('commentCount'));
    $('.create_testimonial_buttons a').removeClass('active');
    $('#testimonial_data').css({ height: '' });
  },

  /**
     Loads the text tab of the testimonial form.

     Note: this is the one which allows the user to enter regular text as their
     testimonial.
  */
  loadTextTab($testimonialForm) {
    const $testimonialField = $testimonialForm.find('#testimonial_data');
    const $testimonialButtons = $testimonialForm
      .siblings('.create_testimonial_buttons');
    const $testimonialTextButton = $testimonialButtons
      .find('.testimonial_text');
    const $testimonialOtherButtons = $testimonialButtons
      .find('.testimonial_photo, .testimonial_video');
    const $testimonialCreateButton = $testimonialForm
      .find('#testimonial_create_button');

    $testimonialForm.removeClass('testimonial_media')
      .addClass('plain-text active');
    $testimonialTextButton.addClass('active');
    $testimonialOtherButtons.removeClass('active');
    $testimonialField.attr('contenteditable', 'true')
      .removeAttr('style').removeClass('testimonial_media');
    $testimonialField.show();
    $testimonialCreateButton.text('Submit');

    // If the testimonial field is not plain text
    if (
      $testimonialField.text().trim() === ''
      && $testimonialField.html() !== ''
    ) {
      $testimonialField.text('');
    }

    const $testimonialTextField = $('#testimonial_data');
    $testimonialTextField.focus();
  },

  /**
     Loads the photo tab of the testimonial form.
  */
  loadPhotoTab($testimonialForm) {
    const $testimonialField = $testimonialForm.find('#testimonial_data');
    const $testimonialButtons = $testimonialForm
      .siblings('.create_testimonial_buttons');
    const $testimonialPhotoButton = $testimonialButtons
      .find('.testimonial_photo');
    const $testimonialOtherButtons = $testimonialButtons
      .find('.testimonial_text, .testimonial_video');
    const $testimonialCreateButton = $testimonialForm
      .find('#testimonial_create_button');
    const placeholderSize = `${$testimonialField.outerWidth().toString()}px`;

    $testimonialForm.removeClass('plain-text active')
      .addClass('testimonial_media');
    $testimonialPhotoButton.addClass('active');
    $testimonialOtherButtons.removeClass('active');
    $testimonialField.attr('contenteditable', 'false')
      .css({ width: placeholderSize, height: placeholderSize })
      .text('').removeClass('testimonial_media');
    $testimonialCreateButton.text('Post photo');
    $('#tmp_testimonial_video').hide();
  },

  /**
     Loads the video tab of the testimonial form.
  */
  loadVideoTab($testimonialForm) {
    const $testimonialField = $testimonialForm.find('#testimonial_data');
    const $testimonialButtons = $testimonialForm
      .siblings('.create_testimonial_buttons');
    const $testimonialPhotoButton = $testimonialButtons
      .find('.testimonial_video');
    const $testimonialOtherButtons = $testimonialButtons
      .find('.testimonial_text, .testimonial_photo');
    const $testimonialCreateButton = $testimonialForm
      .find('#testimonial_create_button');
    const placeholderWidth = `${$testimonialField.outerWidth().toString()}px`;

    // YouTube's height, 16x9 aspect ratio
    const placeholderHeight = `${(315).toString()}px`;

    $testimonialForm.removeClass('plain-text active')
      .addClass('testimonial_media');
    $testimonialPhotoButton.addClass('active');
    $testimonialOtherButtons.removeClass('active');
    $testimonialField.attr('contenteditable', 'false')
      .css({
        width: placeholderWidth,
        height: placeholderHeight,
      }).text('').removeClass('testimonial_media');
    $testimonialCreateButton.text('Post video');
  },

  /**
     Launches the widget that allows a user to select a photo to upload.

     Note: this effectively launches FilePicker to pick the photo.
  */
  launchSelectPhotoWidget() {
    const selectPhoto = SG.pickFile(null, {
      container: 'modal',
      multiple: false, // Limiting to one photo per testimonial
    });

    selectPhoto.done(_.bind(this.photoSelected, this));
  },

  /**
     Photo selected callback

     Note: this is called after the user has picked an image with File Picker.
  */
  photoSelected(fpfiles, fpPolicyData) {
    this.loadPhotoTab(this.$el);
    this.setPreviewLoadingState();

    const self = this;

    const addTestimonialPhoto = this.collection()
      .addFPFiles(fpfiles, fpPolicyData);
    addTestimonialPhoto.done((data) => {
      // After all photos have been added
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const i in fpfiles) {
        const fpfilekey = self.getHashFromTempFilepicker(fpfiles[i].key);
        const templateData = {
          url: fpfiles[i].url,
          fpfile: fpfiles[i],
          fpfilekey,
          policy: fpPolicyData.policy,
          signature: fpPolicyData.signature,
          photo_max_width: '508',
          upload_job_number: data.upload_job_number,
        };

        // Creating the photo HTML structure
        const hbTemplateSource = Handlebars
          .compile($('#blog_photo_template').html());
        const $testimonialContent = self.$el.find('#testimonial_data');
        let photoTestimonialHtml;

        // Add the preview image to the testimonial form
        const newPhoto = new Image();
        newPhoto.src = templateData.url;
        newPhoto.onload = () => {
          $('#testimonial_spinner').hide();
          photoTestimonialHtml = hbTemplateSource(templateData);

          $testimonialContent.addClass('testimonial_media');
          $testimonialContent.html(photoTestimonialHtml);
          self.$el.addClass('active');

          if (newPhoto.height > 338) {
            const scale = 338 / newPhoto.width;
            const scaledHeight = newPhoto.height * scale;
            $testimonialContent.css('height', scaledHeight);
          }

          self.clearPreviewLoadingState();
          SG.reLayout();
        };
      }
    });
  },

  launchSelectVideoWidget($testimonialForm) {
    // Creating a mini Redactor object to handle Video embed modal
    // TODO: Is there a better option?
    const redactorObjId = 'tmp_testimonial_video';
    const redactorObjSettings = $.extend(
      true,
      { buttons: ['video'] },
      SG.redactorSettings,
    );
    let $redactorObj = $('#tmp_testimonial_video');

    if ($redactorObj.length === 0 && !$redactorObj.data('redactor')) {
      // Creating Redactor temporal widget
      $redactorObj = $('<div/>', {
        id: redactorObjId,
        class: 'visuallyhidden',
      });
      $redactorObj.redactor(redactorObjSettings);
    }

    const videoTestimonialRedactor = $redactorObj.data('redactor');

    const self = this;
    // Overriding the 'videoInsert' function only for this mini Redactor
    videoTestimonialRedactor.videoInsert = function videoInsert() {
      self.loadVideoPreview(this);
    };

    // Adding mini Redactor to the testimonial form and launching the Video
    // embed modal
    $testimonialForm.append($redactorObj);
    $testimonialForm.find(`#${redactorObjId}`).data('redactor').videoShow();
    $('#redactor_modal_inner').css({ 'margin-top': '60px' });
    $('#redactor_modal_close').show();

    $('#redactor_modal').on('click', (e) => {
      if (e.target.id === 'redactor_modal') {
        $('#redactor_modal_close').trigger('click');
      }
    });
  },

  loadVideoPreview(redactor) {
    let data = $('#redactor_insert_video_area').val();
    data = redactor.cleanStripTags(data);

    if (data.match('<iframe')) {
      this.finishLoadVideoPreview(redactor, data);
    } else {
      SG.redactorHelper.getEmbedHTML(
        redactor,
        data,
        this.finishLoadVideoPreview,
      );
    }
  },

  finishLoadVideoPreview(obj, data_) {
    let data = data_;
    const $testimonialForm = $('#new_testimonial');
    this.loadVideoTab($testimonialForm);
    data = obj.cleanStripTags(data);
    const $data = $(data).addClass('blog-embed');
    data = $data[0].outerHTML;
    obj.selectionRestore();
    $testimonialForm.addClass('active');
    $('#testimonial_data').html(data);
    $('#testimonial_data').addClass('testimonial_media');
    obj.modalClose();
    SG.reLayout();
  },

  setPreviewLoadingState() {
    $('#testimonial_data').hide();
    $('#testimonial_spinner').show();
    $('#testimonial_spinner').spinner();
    SG.reLayout();
  },

  clearPreviewLoadingState() {
    $('#testimonial_spinner').clearSpinner();
    $('#testimonial_spinner').hide();
    $('#testimonial_data').show();
    SG.reLayout();
  },

  /**
     Event handler when the user clicks one of the icons at the top of the
     testimonial form.

     @param {object} e - JQuery event data.
  */
  tabClickedEvent(e) {
    e.preventDefault();
    const clickedTab = this.getClickedTabFromEvent(e);
    const $testimonialForm = $('#new_testimonial');

    switch (clickedTab) {
      case 'text':
        this.loadTextTab($testimonialForm);
        break;
      case 'photo':
        this.launchSelectPhotoWidget($testimonialForm);
        break;
      case 'video':
        this.launchSelectVideoWidget($testimonialForm);
        break;
      default:
    }

    SG.reLayout();
    return false;
  },

  /**
     Determines which testimonial icon was clicked from the event data

     @param {object} e - JQuery event data.
  */
  getClickedTabFromEvent(e) {
    let tab = '';

    if ($(e.target).hasClass('testimonial_text')) {
      tab = 'text';
    } else if ($(e.target).hasClass('testimonial_photo')) {
      tab = 'photo';
    } else if ($(e.target).hasClass('testimonial_video')) {
      tab = 'video';
    }

    return tab;
  },

  getSelectedTab() {
    let selectedTab = null;

    const children = $('.create_testimonial_buttons nav.tabs ul').children();
    if ($(children[0]).find('a').hasClass('active')) selectedTab = 'text';
    if ($(children[1]).find('a').hasClass('active')) selectedTab = 'photo';
    if ($(children[2]).find('a').hasClass('active')) selectedTab = 'video';

    return selectedTab;
  },

  /**
     Event handler when the user types or pastes text into the editable area.

     @param {object} e - JQuery event data.
   */
  textChangedEvent(e) {
    if (SG.settings.isotope) {
      if (
        $(e.target).data('lastHeight') !== undefined
        && $(e.target).data('lastHeight') !== $(e.target).height()
      ) {
        SG.reLayout();
      }
      if (e.type === 'paste') {
        // Needs a timer otherwise it fires BEFORE the text is in the box.
        setTimeout(SG.reLayout, 10);
      }
      $(e.target).data('lastHeight', $(e.target).height());
    }

    if (
      e.which === 13 && !$(e.target).hasClass('redactor_box')
      && !$(e.target).parent().hasClass('redactor_box')
    ) {
      e.preventDefault();
      $(e.target).closest('form').find('button[type="submit"]')
        .trigger('click');
    }
  },

  formSubmitEvent(e) {
    e.preventDefault();

    this.syncInputValues();

    if (this.collection().hasUploadJobs()) {
      SG.userWarning(this.config.msgStillProcessing);
      this.collection().restartPollingUploadJobStatus();
      return;
    }

    this.save();
  },

  syncInputValues() {
    this.$el.find('[name="text"]')
      .val(this.$el.find('[contenteditable]').html().trim());
  },

  /**
     Saves the testimonial on the server.

     Note: This method is the one that actually sends the POST XHR to
     /members/{username}/testimonials, and then takes the HTML response from
     that call and inserts it into the DOM.
  */
  save() {
    this.replacePreviewPhotos();

    if (this.draftHasFPLinks()) {
      this.showFPLinksError();
      return null;
    }

    this.syncFormToModel(this.$el, this.model());

    if (!this.model().get('text').trim()) {
      return null;
    }

    SG.showSpinner();

    const request = this.model().save();
    request.done(_.bind(this.saveSuccess, this));
    request.fail(_.bind(this.saveFailed, this));

    SG.clearSpinner();

    return request;
  },

  syncFormToModel($form, model) {
    model.set('action', $form.attr('action'));
    model.set('text', $form.find('[name=text]').val());
    model.set('photos', this.collection().models);
  },

  /**
     Checks to see if there are any links to filepicker.io hosted images in
     draft blog post.
   */
  draftHasFPLinks() {
    let hasFpLinks = false;
    const photos = this.config.context.find('img.photo-to-upload');

    photos.each(function eachPhoto() {
      const src = $(this).attr('src') || '';
      if (src.indexOf('//www.filepicker.io/api/file/') >= 0) {
        hasFpLinks = true;
      }
    });

    return hasFpLinks;
  },

  /**
     Shows an error indicating that the blog post draft still contains preview
     images from filepicker.io.
   */
  showFPLinksError() {
    SG.userError(
      'Some of the preview images in your post could not be processed.',
    );
  },

  /**
     Error handler when photos fail to process correctly.

     @param {object} album - an SG album model
   */
  failedPhotosErrorHandler(album) {
    this.saveDraft();

    if (!SG.isEmptyObject(album.config.failed_photos)) {
      const failedFilenames = this.getFailedFilenames(
        album.config.failed_photos,
      );
      SG.showPhotoErrorLightbox(failedFilenames);
    } else {
      SG.userError('Error saving photos');
    }

    this.loadDefaultState();
    album.removeFailedPhotos();
  },

  /**
     Gets the filenames from the photos that failed to upload.

     @param {object} failed photos data returned from Album module
     @returns {array} filenames only extracted from the failed photos data
   */
  getFailedFilenames(failedPhotos) {
    const failedFilenames = [];
    let key;

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (key in failedPhotos) {
      failedFilenames.push(failedPhotos[key].filename);
    }

    return _.uniq(failedFilenames);
  },

  /**
   Event handler triggered when new photos from File Picker
   have been added to the collection.
   */
  fpfilesSuccessfulHandler() {
    this.replacePreviewPhotos();
  },

  /**
   Event handler when files added to the collection from Filepicker
   have been rejected by the server.
   */
  fpfilesFailedHandler() {
    this.saveDraft();

    if (!SG.isEmptyObject(this.collection().failedPhotos)) {
      let i;
      const failedFilenames = [];

      // eslint-disable-next-line no-plusplus
      for (i = 0; i < this.collection().failedPhotos.length; i++) {
        failedFilenames.push(this.collection().failedPhotos[i].get('filename'));
        this.collection().removeAndRenumber(this.collection().failedPhotos[i]);
      }

      SG.showPhotoErrorLightbox(failedFilenames);
    } else {
      SG.userError('Error saving photos');
    }

    this.removeFailedPhotosFromEditor(this.collection().failedPhotos);
  },

  /**
     Replaces the temporary Filepicker preview images with the processed, SG
     hosted images in the editor.

     @param {array} photos - successfully processed photo details from server.
   */
  replacePreviewPhotos() {
    let i;
    let fpfilekey;
    let urls;
    let photoTarget;
    let attributes;

    const photos = this.collection().models;

    // eslint-disable-next-line no-plusplus
    for (i = 0; i < photos.length; i++) {
      // eslint-disable-next-line no-continue
      if (!photos[i].get('thumbs_generated')) continue;

      fpfilekey = this.getHashFromTempFilepicker(photos[i].get('key'));
      urls = photos[i].get('urls');
      photoTarget = $(`#fp-${fpfilekey}`);

      attributes = {
        number: photos[i].get('number'),
        'data-original': urls.original,
        'data-height': photos[i].get('height'),
        'data-width': photos[i].get('width'),
        src: urls[this.config.geometry],
      };
      photoTarget.attr(attributes);
      photoTarget.removeClass('photo-to-upload');
    }
  },

  /**
     Gets the hash embeded in a Filepicker key.

     @param {string} the Filepicker key for an image (in the format
     "temp/{hash}_{filename}.jpg")

     @returns {string} just the hash component from the filepicker key.
   */
  getHashFromTempFilepicker(key) {
    return key.split('/')[1].split('_')[0];
  },

  /**
     Callback when the post could not be saved.
   */
  saveFailed() {
    SG.userError('Error saving post.');
  },

  saveSuccess(data) {
    const $newTestimonial = $(data);
    SG.initImages($newTestimonial);
    // insert it after the testimonals box.
    $newTestimonial.insertAfter('#article-feed article:eq(0)');

    if ($('img.photo-testimonial').length) {
      $('img.photo-testimonial')[0].onload = () => SG.reLayout();
    }

    $('#article-feed').isotope('reloadItems');
    $('#article-feed').isotope({ sortBy: 'original-order' });
    SG.reLayout();
    SG.createTextLinks($newTestimonial.find('p'));
    this.$el.find('#testimonial_data').removeClass('testimonial_media');

    if (this.getSelectedTab() === 'photo') {
      $('#testimonial_data').css('height', 338);
    }

    this.deactivate(this.$el);
    this.loadDefaultState();
  },

  /**
     Note: dirty hack that sets the text of the testimonial to
     ALBUM:{album_id}, if this was a photo testimonial
  */
  setPhotoTestimonialBody(context) {
    const albumID = context.data('Album').config.album_id;
    context.find('[name="text"]').val(`ALBUM:${albumID}`);
  },

  /**
     Removes failed photo from editor area and returns it to default state.
   */
  removeFailedPhotosFromEditor() {

  },

  /**
     Checks to see if the current draft in the editor is empty.
   */
  draftIsEmpty() {
    return !!this.$el.find(this.config.editorContainer).text();
  },

  /**
     Serializes the current draft in the editor.
   */
  serializeDraft() {
    const draft = {
      text: this.$el.find(this.config.editorContainer).text(),
    };

    return JSON.stringify(draft);
  },

  /**
     Event handler when the user clicks the X icon to close select file modal
     dialog.

     @param {object} e - JQuery event data.
  */
  modalClosedEvent() {
  },

  /**
     Determines whether the testimonial form contains data entered by the user.

     @return {boolean} - whether the user has entered data into the testimonial
     form or not
  */
  hasUserInput() {
    let hasUserInput = false;
    const selectedTab = this.getSelectedTab();
    switch (selectedTab) {
      case 'text':
        hasUserInput = $('#testimonial_data').text() !== '';
        break;
      case 'photo':
        hasUserInput = true;
        break;
      case 'video':
        hasUserInput = true;
        break;
      default:
    }
    return hasUserInput;
  },

  isPhotoVideoTestimonial() {
    return this.$el.hasClass('testimonial_media');
  },

  /**
     Deactivates the comments text box, returning it to its default state.
  */
  deactivate($node) {
    $node.closest('form').removeClass('active');
  },

  focusEvent() {
    const selectedTab = this.getSelectedTab();
    if (selectedTab !== 'text') {
      this.loadTextTab(this.$el);
    }
    SG.reLayout();
  },

  blurEvent() {
    if (!this.hasUserInput()) {
      this.loadDefaultState();
    }
    SG.reLayout();
  },
});
