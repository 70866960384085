import initOverlay from './overlay';
import initProfileSearch from './profileSearch';

import './profile-search.less';

export function init() {
  initOverlay();
  initProfileSearch();
}

export default init;
