import Handlebars from 'handlebars';

export function init() {
  $('document').ready(() => {
    // Adding a new tag to an album
    $('#addTag').click(function addTag(e) {
      e.preventDefault();
      const albumId = $(this).attr('data-album-id');
      const tag = $('#id_tag').val();

      $.ajax({
        url: '/api/add_tag_to_album/',
        type: 'POST',
        dataType: 'json',
        data: JSON.stringify({ album_id: albumId, tag }),
        contentType: 'application/json; charset=UTF-8',
      })
        .done(() => {
          // TODO ask API for title and author
          const $source = $('#tag_template');
          // compile
          const template = Handlebars.compile($source.html());
          // insert data into handlbars
          const templateData = {
            name: tag,
            user_url: SG.user.logged_in_user_url,
            username: SG.user.logged_in_username,
          };
          const templateWithData = template(templateData);
          const $newTag = $(templateWithData).addClass('collapsed');
          $('ul.tag-list').append($newTag);
          setTimeout(() => { $newTag.addClass('uncollapsed'); }, 0);
          $('#id_tag').val('');
        })
        .fail((data) => {
          SG.userError(data.responseText);
        });
    });

    // Removing a tag from an album
    $('ul.tag-list.editable').on('click', '.remove-tag', function removeTag(e) {
      e.preventDefault();
      const $that = $(this);
      $.ajax({
        type: 'POST',
        url: '/api/remove_tag_from_album/',
        data: JSON.stringify({
          album_id: $that.closest('.tag-list').data('album_id'),
          tag: $that.siblings('h5').find('a').text(),
        }),
        contentType: 'application/json; charset=UTF-8',
      })
        .done(() => {
          $that.closest('li').addClass('collapsed').removeClass('uncollapsed');
          setTimeout(() => { $that.closest('li').remove(); }, 500);
        })
        .fail(() => {
          SG.userError(
            'Could not remove tag at this time. Please try again later.',
          );
        });
    });

    // Loading more album tags
    $('body').on('click', '.load_album_tags', function loadAlbumTags(e) {
      e.preventDefault();
      const albumId = $('.tag-list').attr('data-album_id');
      const $loadMoreTags = $(this);
      const $albumTagList = $loadMoreTags.siblings('.tag-list');
      const page = $loadMoreTags.data('next_page') || '2';
      $loadMoreTags.addClass('loading');
      $.ajax({
        type: 'GET',
        url: '/api/album_tags/',
        data: {
          page,
          album_id: albumId,
        },
      })
        .done((data) => {
          $loadMoreTags.clearSpinner().removeClass('loading');
          // Append tags to album tag list
          const tagList = _.map(
            data.results,
            (tag) => $(tag.tag_html).addClass('collapsed'),
          );
          $albumTagList.append(tagList);
          // Cascading collapse
          _.each(tagList, (el, i) => {
            const $el = $(el);
            setTimeout(() => { $el.addClass('uncollapsed'); }, i * 100);
          });

          $loadMoreTags.data('next_page', data.next_page);

          if (!data.next_page_exists) {
            // There's no more tags to load: remove the button
            $loadMoreTags.remove();
          } else {
            // Updating the button label
            $loadMoreTags.find('span.tags_count_next').text(data.count_next);
          }
          if (SG.settings.isotope) {
            SG.reLayout();
          }
        })
        .fail((data) => {
          SG.userError(data.responseText);
        });
    });
  });
}
export default init;
