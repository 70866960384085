import AddMoneyDialogView from './AddMoneyDialogView';
import SendTipDialogView from './SendTipDialogView';

export default {
  /**
   Show the add money pop up.
  */
  showAddMoneyPopup() {
    $.fancybox({
      autoDimensions: false,
      type: 'inline',
      content: '<div class="fancy-add-money-dialog"></div>',
      fitToView: true,
      closeClick: false,
      openEffect: 'none',
      closeEffect: 'none',
      closeBtn: false,
      padding: 0,
      margin: 10,
      width: 760,
    });

    const view = new AddMoneyDialogView({
      el: '.fancy-add-money-dialog',
      returnURL: window.location.href,
    });
    view.listenTo(view, 'close', () => {
      view.destroy();
      $.fancybox.close();
      this.clearAddMoneyHash();
    });
    view.render();
    view.onShow();
  },

  showTipDialog(contentTypeId, objectId, recipients, galleryId) {
    const $content = $(
      '<div><div class="fancy-tip-dialog"></div><div class="fancy-send-dialog"'
      + 'style="display:none;"></div></div>',
    );

    const sendView = new SendTipDialogView({
      el: $content.find('.fancy-tip-dialog'),
      recipients: String(recipients),
      contentTypeId,
      objectId,
    });

    let returnURL = window.location.href.split('#')[0];

    if (galleryId) {
      returnURL += `#gallery-tip-${galleryId}-${contentTypeId}-${objectId}`;
    } else {
      returnURL += `#tip-${contentTypeId}-${objectId}`;
    }

    const addView = new AddMoneyDialogView({
      el: $content.find('.fancy-send-dialog'),
      backButton: true,
      returnURL,
    });

    sendView.listenTo(sendView, 'close', _.bind(() => {
      // User clicked X on the tip dialog
      sendView.destroy();
      addView.destroy();
      $.fancybox.close();
    }, this));

    sendView.listenTo(sendView, 'complete', _.bind(function complete() {
      // Tip was sent successfully
      sendView.destroy();
      addView.destroy();
      $.fancybox.close();
      this.markTipped(contentTypeId, objectId);
    }, this));

    sendView.listenTo(sendView, 'add_more', () => {
      // User clicked button to add more money
      $('.fancy-tip-dialog').hide();
      $('.fancy-send-dialog').show();
      addView.onShow();
      if (addView.usePaymentForm()) {
        // The box doesn't recenter. There should be a method, but our code
        // is likely old.
        $('.fancybox-wrap').css({ top: '60px' });
      }
    });

    addView.listenTo(addView, 'back', () => {
      // User clicked the back button on the add money dialog
      $('.fancy-tip-dialog').show();
      $('.fancy-send-dialog').hide();
    });

    addView.listenTo(addView, 'close', () => {
      // User clicked X on the add money dialog
      sendView.destroy();
      addView.destroy();
      $.fancybox.close();
    });

    addView.listenTo(addView, 'money_added', (newBalance) => {
      sendView.renderBalance(newBalance);
      $('.fancy-tip-dialog').show();
      $('.fancy-send-dialog').hide();
    });

    sendView.render();
    addView.render();

    $.fancybox({
      autoDimensions: false,
      type: 'inline',
      content: $content,
      fitToView: true,
      closeClick: false,
      openEffect: 'none',
      closeEffect: 'none',
      closeBtn: false,
      padding: 0,
      margin: 10,
      width: 300,
    });
  },

  hashIsAddMoney() {
    return window.location.hash === '#addmoney';
  },

  clearAddMoneyHash() {
    if (this.hashIsAddMoney()) {
      window.location.hash = '';
    }
  },

  markTipped(contentTypeId, objectId) {
    const $tipButtons = $(
      `a.tip[data-content_type_id=${contentTypeId}][data-object_id=${objectId}]`
      + `,button.tip[data-content_type_id=${contentTypeId}]`
      + `[data-object_id=${objectId}]`,
    );
    $tipButtons.addClass('tipped');
  },

  deleteTipPostEvent(e) {
    const action = $(e.target).attr('action');
    const ajaxArgs = {
      dataType: 'json',
      data: JSON.stringify({}),
      url: action,
      contentType: 'application/json; charset=UTF-8',
    };
    const request = SG.apiDelete(ajaxArgs);

    request.done(() => {
      const $article = $(e.target).closest('article');
      $article.remove();
      SG.isotope.isotope('remove', $article);
      SG.reLayout();
    });

    request.fail(() => {
      SG.userError('Error deleting post');
    });
  },
};
