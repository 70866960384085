export function updatePostalForm(addressForm, countryEl) {
  const ajaxArgs = {
    dataType: 'html',
    data: addressForm.find('select, textarea, input').serialize(),
    url: addressForm.attr('action'),
  };
  SG.apiPost(ajaxArgs).done((data) => {
    const newForm = JSON.parse(data);
    addressForm.html(newForm.postal_address);
    $(countryEl).focus(); // focus lost after html update
  });
}

export function postalFormMonitor() {
  $(() => {
    $(document).on('change', '#id_country', () => {
      const addressForm = $('#address-form');
      updatePostalForm(addressForm, '#id_country');
    });

    $(document).on('change', '#id_billing_country', () => {
      const addressForm = $('#address-form-billing');
      updatePostalForm(addressForm, '#id_billing_country');
    });

    $(document).on('change', '#id_shipping_country', () => {
      const addressForm = $('#address-form-shipping');
      updatePostalForm(addressForm, '#id_shipping_country');
    });

    $(document).on('change', '#id_affiliate_country', () => {
      const addressForm = $('#address-form-affiliate');
      updatePostalForm(addressForm, '#id_affiliate_country');
    });
  });
}
export default postalFormMonitor;
