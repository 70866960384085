import Notifications from './Notifications';
import './notifications.less';

export function init() {
  $(() => {
    $(document).on('click', '.say-thanks', Notifications.focusThanksEvent);
    $(document).on(
      'click',
      '.cash-out',
      _.bind(Notifications.clickCashOutEvent, Notifications),
    );
    $(document).on(
      'click',
      '.add-money',
      _.bind(Notifications.clickAddMoneyEvent, Notifications),
    );
    $(document).on(
      'click',
      '.affiliate-share-facebook',
      _.bind(Notifications.shareAffiliateLinkFacebook, Notifications),
    );
    $(document).on(
      'click',
      '.affiliate-share-twitter',
      _.bind(Notifications.shareAffiliateLinkTwitter, Notifications),
    );

    function checkHash() {
      if (
        window.location.hash === '#addmoney' && SG.user.status === 'enabled'
      ) {
        Notifications.clickAddMoneyEvent();
      }
    }

    $(window).on('hashchange', checkHash);
    checkHash();

    // eslint-disable-next-line no-new
    new Clipboard('#affiliate-link-copy-button');
  });
}

export default init;
