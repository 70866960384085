export function init() {
  $(() => {
    $(document).on('click', '#ad .close', (e) => {
      e.preventDefault();
      const cookieName = $('#ad').data('cookie');
      const cookieValue = 'True';
      const cookieExpiry = $('#ad').data('cookie-expiry');
      const currentDate = new Date();
      const cookieExpiresTime = (
        currentDate.getTime() + (parseInt(cookieExpiry, 10) * 1000)
      );
      const cookieExpires = new Date(cookieExpiresTime);
      $.cookie(cookieName, cookieValue, { path: '/', expires: cookieExpires });
      SG.apiNoPromptDelete({ url: '/ads/burlesque' });
      $('#ad').hide();
    });

    $('#ad').on('click', (e) => {
      if (!$(e.target).hasClass('close') && !$(e.target).is('a')) {
        window.open($('#ad').data('url'));
      }
    });

    $(document).on('click', '#generic-ad .close', (e) => {
      e.preventDefault();

      const genericAd = $('#generic-ad').data('generic-ad');
      const cookieName = $('#generic-ad').data('cookie');

      const cookieValue = $.cookie(cookieName) || '';
      const cookieValuesArray = cookieValue.split(',');

      const newCookieValuesArray = cookieValuesArray.filter((el) => el);

      const hasThisAd = newCookieValuesArray.includes(genericAd);

      if (!hasThisAd) {
        newCookieValuesArray.push(genericAd);
      }

      const newCookieValue = newCookieValuesArray.join();

      const cookieExpiry = $('#generic-ad').data('cookie-expiry');
      const currentDate = new Date();
      const cookieExpiresTime = (
        currentDate.getTime() + (parseInt(cookieExpiry, 10) * 1000)
      );
      const cookieExpires = new Date(cookieExpiresTime);
      $.cookie(
        cookieName,
        newCookieValue,
        { path: '/', expires: cookieExpires },
      );
      SG.apiNoPromptDelete({ url: `/ads/${genericAd}/` });

      $('#generic-ad').hide();
    });

    $('#generic-ad').on('click', (e) => {
      if (!$(e.target).hasClass('close') && !$(e.target).is('a')) {
        window.open($('#generic-ad').data('url'));
      }
    });
  });
}
export default init;
