export default Backbone.Model.extend({
  /**
   Sync the model data to the server.

   Note: overrides Backbone's default sync method.
   */
  sync(method) {
    let request;

    switch (method) {
      case 'create':
      case 'update':
        request = this.sendRequest();
        break;
      case 'delete':
        request = this.sendDeleteRequest();
        break;
      case 'read':
      default:
        request = $.Deferred();
        request.reject();
    }

    return request;
  },

  /**
   Send the request to create or edit a testimonial to the server.
   */
  sendRequest() {
    const data = this.assembleFormData();

    return $.ajax({
      type: 'POST',
      url: this.attributes.action,
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(data),
      xhrFields: {
        withCredentials: true,
      },
    });
  },

  /**
   Assemble the form data into the object sent to server.
   */
  assembleFormData() {
    const data = {
      text: this.attributes.text,
      noteditable: '',
    };

    if (this.attributes.photos) {
      const photoData = [];

      _.each(this.attributes.photos, (photo) => {
        photoData.push({
          id: photo.get('id'),
          album_photo_id: photo.get('album_photo_id'),
          caption: photo.get('caption'),
          number: photo.get('number'),
          isCover: photo.get('isCover'),
          delete: photo.get('delete'),
          fpfilekey: photo.get('key'),
        });
      });

      if (photoData) {
        data.photos = photoData;
      }
    }

    return data;
  },

  /**
     Sends the request to delete a blog post to the server.
   */
  sendDeleteRequest() {
    const ajaxArgs = {
      url: this.attributes.action,
      type: 'DELETE',
      dataType: 'json',
    };

    return $.ajax(ajaxArgs);
  },
});
