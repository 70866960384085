import { FormUtils } from '../join/FormUtils';

const SG = window.SG || {};

const handleActionRequired = async (actionRequired) => {
  // Handle any redirs found (Promise never completes).
  // Otherwise, resolve with paymentIntent or reject with message.
  const stripe = window.initStripe();
  // Uses a real Promise
  const result = await stripe.handleCardPayment(actionRequired.clientSecret);
  if (result.error) {
    if (actionRequired.failRedir) {
      FormUtils.handleResponseRedir(actionRequired.failRedir);
    } else {
      throw result.error.message;
    }
  }
  if (actionRequired.successRedir) {
    FormUtils.handleResponseRedir(actionRequired.successRedir);
  }
  return result.paymentIntent;
};

export default async function intentApiPost(ajaxArgs) {
  /*
  * Act like SG.apiPost(), but handle any actionRequired
  * data found in the response (including redirs).
  * If handle actionRequired and don't redir, append data with the
  * paymentIntent from Stripe. If error from Stripe, use to reject.
  */
  const args = ajaxArgs || {};
  args.dataType = 'json';
  const data = await SG.apiPost(args);
  if (('actionRequired' in data) && (data.actionRequired)) {
    const paymentIntent = await handleActionRequired(data.actionRequired);
    data.paymentIntent = paymentIntent;
  }
  return data;
}
