import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-quality-selector-hls';

import './video.less';

function resizePlayer(player) {
  const $el = $(player.el());
  const width = $el.parent().width();
  const height = width * (9 / 16);
  $el.width(width);
  $el.height(height);
}

export function initVideo(video) {
  // Having issues in IOS, which only supports native HLS.
  const overrideNative = typeof MediaSource !== 'undefined';
  const player = videojs(video.id, {
    html5: {
      fluid: true,
      vhs: { overrideNative },
    },
  });
  const currentSource = player.currentSource();
  const currentSourceUrl = new URL(currentSource.src);

  player.ready(() => {
    resizePlayer(player);
    // Add HLS Quality Selector for videos with a .m3u8 playlist
    // (if not using native -- not supported in native).
    if (
      overrideNative
      && currentSource
      && currentSource.type === 'application/x-mpegURL'
    ) {
      player.qualitySelectorHls({
        displayCurrentQuality: true,
      });

      // This is a hack to allow us to sign requests to our media storage.
      player.tech(1).vhs?.xhr.onRequest((options) => {
        const url = new URL(options.uri);
        if (url.hostname === currentSourceUrl.hostname) {
          // If requesting to our media storage, replace the search params
          // with the currentSource params to copy any signature
          // (which should be signed based on url prefix to
          // allow this to work if same prefix).
          url.search = currentSourceUrl.search;
          options.uri = url.toString();
        }
      });
    }
    $(player.el()).parent().addClass('loaded');
    $(window).smartresize(() => resizePlayer(player));
  });
}

/**
 * Initialize videoJS instances and listen for new video DOM elements
 * fetched via the "load more" pagination
 */
export function init() {
  $(document).ready(() => {
    document.querySelectorAll('.video-js').forEach((video) => initVideo(video));

    // Listen for new videos inserted via load more pagination
    $(document).on('click', '#load-more', async function loadMore() {
      const data = await $(this).data('finished').done();
      $(data).find('.video-js').each(function eachVideo() {
        initVideo(this);
      });
    });
  });
}

// grant global access, for legacy js and debug access
window.videojs = videojs;

export default init;
