import Handlebars from 'handlebars';

export default Marionette.ItemView.extend({
  filepickerArgs: {
    container: 'modal',
    multiple: false,
  },

  template: $('#affiliate-registration-dialog-template').length > 0
    ? Handlebars.compile($('#affiliate-registration-dialog-template').html())
    : '',

  events: {
    'click .icon-close': 'clickCloseEvent',
    'click .btn-request-cash-out': 'clickRequestCashOutEvent',
    'change input': 'inputChangedEvent',
    'change select': 'inputChangedEvent',
    'change #id_country': 'countryChangedEvent',
    'keyup input': 'inputChangedEvent',
    'change select[name="payout_method"]': 'payoutMethodChangedEvent',
    'click .btn-upload-w9': 'clickUploadW9Event',
    'click .delete-w9': 'deleteW9Event',
    submit: 'formSubmitEvent',
  },
  PAYOUT_CHECK: '0',
  PAYOUT_PAYPAL: '1',
  PAYOUT_DWOLLA: '3',
  PAYOUT_OTHER: '5',
  PAYOUT_PAXUM: '6',
  PAYOUT_PMI: '7',

  initialize(options) {
    this.options = options;

    this.model = new AlbumModel({
      id: this.$el.data('album-id'),
      albumType: SG.ALBUM_TYPE_AFFILIATE_W9,
      type: 'affiliate_w9',
      name: 'Untitled',
      description: '',
      allow_tagging: false,
      tags: '',
      privacy: 'public',
    });

    this.collection = new PhotoCollection([], {
      albumType: 'affiliate_w9',
    });

    this.togglePaymentMethods();

    this.collection.on('fpfiles_added', this.fpfilesAddedHandler, this);
    this.collection.on(
      'fpfiles_successful',
      this.fpfilesSuccessfulHandler,
      this,
    );
    this.collection.on('fpfiles_failed', this.fpfilesFailedHandler, this);

    this.filepickerArgs.done = _.bind(this.fpDone, this);
  },

  serializeData() {
    return {
      amount: this.options.amount,
      purchased: this.options.purchased,
    };
  },

  onRender() {
    this.validate();
    this.reloadStates();
  },

  clickCloseEvent(e) {
    e.preventDefault();
    this.trigger('close');
  },

  inputChangedEvent() {
    this.validate();
  },

  countryChangedEvent(e) {
    e.preventDefault();
    this.reloadStates();
    this.togglePaymentMethods();
  },

  getStateValue() {
    let $el;

    if (this.$el.find('select[name="state_or_province"]').is(':visible')) {
      $el = this.$el.find('select[name="state_or_province"]');
    } else {
      $el = this.$el.find('input[name="state_or_province"]');
    }

    return $el.val();
  },

  reloadStates() {
    const countryCode = this.$el.find('select[name="country"]').val();
    const apiUrl = `/api/states_or_provinces/?country_code=${countryCode}`;
    const request = SG.apiGet(apiUrl);
    request.done(_.bind(this.loadStates, this));
  },

  loadStates(data) {
    const currentState = this.$el.find('input[name="state_or_province"]').val();
    this.$el.find('select[name="state_or_province"]').empty();

    if (data.length) {
      // Show Select
      const self = this;
      $.each(data, (idx, state) => {
        const selected = currentState === state.code ? ' selected' : '';
        const option = (
          `<option value="${state.code}"${selected}>${state.name}</option>`
        );
        self.$el.find('select[name="state_or_province"]').append(option);
      });
      this.$el.find('input[name="state_or_province"]').hide();
      this.$el.find('.state-choices').show();
    } else {
      // Show Free-Form
      this.$el.find('input[name="state_or_province"]').val('');
      this.$el.find('input[name="state_or_province"]').show();
      this.$el.find('.state-choices').hide();
    }
  },

  togglePaymentMethods() {
    const country = this.$el.find('select[name="country"]').val();
    const $payoutMethod = this.$el.find('select[name="payout_method"]');
    const that = this;

    SG.apiGet(`/payouts/methods/?country=${country}`).done((data) => {
      $payoutMethod.empty();
      $.each(data.choices, (index, choice) => {
        $payoutMethod.append(new Option(choice.label, choice.value));
      });

      that.togglePaymentMethodDetails();
    });
  },

  validate() {
    if (this.validateForm()) {
      this.enableCTAButton();
    } else {
      this.disableCTAButton();
    }
  },

  validateForm() {
    const data = this.getFormData();

    if (!data.legal_first_name) {
      return false;
    }

    if (!data.legal_last_name) {
      return false;
    }

    if (!data.country) {
      return false;
    }

    if (!data.address) {
      return false;
    }

    if (!data.city) {
      return false;
    }

    if (!this.getStateValue()) {
      return false;
    }

    if (!data.payout_method || data.payout_method === 'null') {
      return false;
    }

    if (data.payout_method === this.PAYOUT_DWOLLA) {
      if (!data.routing_number || !data.account_number || !data.account_type) {
        return false;
      }
    }
    if (data.payout_method === this.PAYOUT_OTHER && !data.instructions) {
      return false;
    }

    if ([this.PAYOUT_PMI, this.PAYOUT_PAXUM].indexOf(data.payout_method) >= 0) {
      if (!data.email || !/\S+@\S+\.\S+/.test(data.email)) {
        return false;
      }
    }

    if (data.country === 'US' && !this.model.get('id')) {
      return false;
    }

    return true;
  },

  enableCTAButton() {
    this.$el.find('.btn-request-cash-out').prop('disabled', false);
  },

  disableCTAButton() {
    this.$el.find('.btn-request-cash-out').prop('disabled', true);
  },

  clickUploadW9Event(e) {
    e.preventDefault();
    this.launchFilepicker();
  },

  /**
     Launch the Filepicker tool, and add the photos when done.
   */
  launchFilepicker() {
    $('.fancybox-overlay').css('z-index', '0');

    SG.pickFile(null, this.filepickerArgs)
      .then(_.bind(this.collection.addFPFiles, this.collection));
  },

  /**
     Filepicker files added handler.

     Note: this means Filepicker has closed and they have been submitted to the
     SG server via the add photos endpoint, but this doesn't mean they are
     finished processing yet.
   */
  fpfilesAddedHandler() {
    $('.fancybox-overlay').css('z-index', '8010');
    SG.showSpinner();
  },

  /**
     Filepicker files successful handler.

     Note: this means the new profile photo has been added to SG and assigned a
     photo record, but it has not been saved to the profile album as an album
     photo at this point yet.
   */
  fpfilesSuccessfulHandler() {
    this.collection.setCoverToLastPhoto();
    this.model.set('photos', this.collection.models);

    this.model.save()
      .then(_.bind(this.w9Success, this));
  },

  /**
     W9 photo successfully saved handler.

     Called when polling for thumbnail status on SG server finishes
     successfully.
   */
  w9Success() {
    this.validate();
    const previewUrl = this.collection.models[0].get('fpfile').url;
    const { filename } = this.collection.models[0].get('fpfile');
    this.hideCorruptMessage();
    this.$el.find('.w9 .preview-link').attr('href', previewUrl);
    this.$el.find('.w9 .w9-fn').text(filename);
    this.$el.find('.w9 button').hide();
    this.$el.find('.uploaded-w9').show();
    SG.clearSpinner();
  },

  /**
     File picker done handler

     This is always closed when Filepicker closes, even if no file has been
     uploaded.
  */
  fpDone() {
    $('.fancybox-overlay').css('z-index', '8010');
  },

  /**
     Filepicker files failed, could not be processed by SG server.
   */
  fpfilesFailedHandler() {
    this.validate();
    SG.clearSpinner();

    if (!SG.isEmptyObject(this.collection.failedPhotos)) {
      let i;
      const failedFilenames = [];

      // eslint-disable-next-line no-plusplus
      for (i = 0; i < this.collection.failedPhotos.length; i++) {
        failedFilenames.push(this.collection.failedPhotos[i].get('filename'));
        this.collection.removeAndRenumber(this.collection.failedPhotos[i]);
      }
    }

    this.showCorruptMessage();
  },

  /**
     Show message to user saying uploaded W9 is corrupt.
   */
  showCorruptMessage() {
    this.$el.find('.corrupt-w9').show();
  },

  /**
     Hide message to user saying uploaded W9 is corrupt.
   */
  hideCorruptMessage() {
    this.$el.find('.corrupt-w9').hide();
  },

  /**
     User clicks the X icon to delete the uploaded W9.
   */
  deleteW9Event(e) {
    e.preventDefault();
    this.model.set('id', null);
    this.collection.reset();
    this.$el.find('.w9 button').show();
    this.$el.find('.uploaded-w9').hide();
    this.validate();
  },

  /**
     User changes the payout method selection.
   */
  payoutMethodChangedEvent() {
    this.togglePaymentMethodDetails();
  },

  togglePaymentMethodDetails() {
    const selectedMethod = $('select[name="payout_method"]').val();

    const brokerForms = {
      dwolla: $('.dwolla-form'),
      other: $('.other-form'),
      paxum: $('.paxum-form'),
      paypal: $('.paypal-form'),
      pmi: $('.pmi-form'),
    };

    const hideBrokerForms = () => {
      $.each(brokerForms, (name, form) => {
        form.find('input,select,textarea').prop('disabled', true);
        form.hide();
      });
    };

    const enableBrokerFormFields = (brokerForm) => {
      brokerForm.find('input,select,textarea').prop('disabled', false);
    };

    switch (selectedMethod) {
      case this.PAYOUT_CHECK:
        hideBrokerForms();
        break;
      case this.PAYOUT_DWOLLA:
        hideBrokerForms();
        enableBrokerFormFields(brokerForms.dwolla);
        brokerForms.dwolla.show();
        break;
      case this.PAYOUT_OTHER:
        hideBrokerForms();
        enableBrokerFormFields(brokerForms.other);
        brokerForms.other.show();
        break;
      case this.PAYOUT_PAXUM:
        hideBrokerForms();
        enableBrokerFormFields(brokerForms.paxum);
        brokerForms.paxum.show();
        break;
      case this.PAYOUT_PAYPAL:
        hideBrokerForms();
        enableBrokerFormFields(brokerForms.paypal);
        brokerForms.paypal.show();
        break;
      case this.PAYOUT_PMI:
        hideBrokerForms();
        enableBrokerFormFields(brokerForms.pmi);
        brokerForms.pmi.show();
        break;
      default:
        hideBrokerForms();
    }
  },

  clickRequestCashOutEvent(e) {
    e.preventDefault();
    const request = this.sendRequestCashOutRequest();
    const self = this;
    request.done(() => {
      self.trigger('complete');
    });
    request.fail((jqXHR) => {
      const data = $.parseJSON(jqXHR.responseText);
      let errorMessage = SG.userLang() === 'es'
        ? 'No se pudo guardar el registro' : 'Error saving registration';
      if (data.detail) {
        if (data.detail.email) {
          errorMessage = `${errorMessage}. ${data.detail.email}`;
        } else {
          errorMessage = `${errorMessage}. ${data.detail}`;
        }
      }
      SG.userError(errorMessage);
    });
  },

  sendRequestCashOutRequest() {
    const data = $.extend({}, this.getFormData(), {
      state_or_province: this.getStateValue(),
      w9_album: this.model.get('id'),
    });

    const ajaxArgs = {
      url: '/api/affiliate-register/',
      dataType: 'json',
      data,
    };

    return SG.apiPost(ajaxArgs);
  },

  /**
   * Returns JS object of the form data
   */
  getFormData() {
    return this.$el.find('form').serializeObject();
  },

  formSubmitEvent(e) {
    e.preventDefault();
  },
});
