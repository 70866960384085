import Handlebars from 'handlebars';

import paymentFormInit from '../join/processPayment';
import { postalFormMonitor } from '../postal';
import intentApiPost from '../stripe/HandleActionRequired';

const template = document.getElementById('tipping-add-money-template')
  ? Handlebars.compile(
    document.getElementById('tipping-add-money-template').innerHTML,
  ) : '';

let paymentFormHandler = null;
let paymentFormDisabled = false;

export default Marionette.ItemView.extend({
  template,

  events: {
    'click .icon-arrow-left': 'clickBackEvent',
    'click .icon-close': 'clickCloseEvent',
    'change #add-money-amount': 'changedAmount',
    'click .btn-add-money': 'confirmAddMoneyClickEvent',
  },

  onShow() {
    // Triggered by tipping.js, not marriotte.
    // Form container existence is flag that we need to load in card form.
    if (this.usePaymentForm()) {
      this.initPaymentForm();
    }
    // Update button state for amount
    this.changedAmount();
  },

  usePaymentForm() {
    return !$('.fund-payment-form').is(':hidden') && !paymentFormDisabled;
  },

  disablePaymentForm() {
    $('.fund-payment-form').hide();
    paymentFormHandler = null;
    paymentFormDisabled = true;
    const text = $('.btn-add-money').data('storedCard');
    $('.btn-add-money').text(text);
  },

  disableFundingOffer() {
    $('.funding-bonus').remove();
  },

  async initPaymentForm() {
    const ajaxArgs = {
      url: '/payment/ecsuite/fund/',
    };
    const result = await SG.apiGet(ajaxArgs);
    const $form = $('.fund-payment-form');
    $form.html(result);
    const text = $('.btn-add-money').data('newCard');
    $('.btn-add-money').text(text);
    $('.fund-payment-form').show();
    // Hack to force the fancybox to adjust for the new content height
    // from adding the card form. Newer versions of fancybox have a
    // a method to trigger this, but ours doesn't seem to.
    window.dispatchEvent((new Event('resize')));
    await this.loadScripts();
    paymentFormHandler = await paymentFormInit();
    postalFormMonitor();
    paymentFormDisabled = false;

    // Not certain why, but the above enables the button, so re-evaluate.
    this.changedAmount();
  },

  async loadScripts() {
    const pendingScripts = Array.from(document.querySelectorAll('.pending-script'));
    if (!pendingScripts.length) {
      return Promise.resolve();
    }
    const loadScriptPromises = pendingScripts.map((el) => this.loadPendingScript(el));
    return Promise.all(loadScriptPromises);
  },

  async loadPendingScript(pendingScriptEl) {
    const scriptEl = document.createElement('script');
    Array.from(pendingScriptEl.attributes).forEach((attr) => {
      if (attr.name.startsWith('data-')) {
        // remove 'data-' prefix
        const nameWithoutPrefix = attr.name.slice(5);
        scriptEl.setAttribute(nameWithoutPrefix, attr.value);
      }
    });
    const promise = new Promise((resolve, reject) => {
      scriptEl.onload = () => {
        resolve();
      };
      scriptEl.onerror = () => {
        reject(new Error('Script load error'));
      };
    });
    document.head.appendChild(scriptEl);
    // delete the original element to avoid duplicates
    pendingScriptEl.remove();
    return promise;
  },

  serializeData() {
    return {
      backButton: !!this.options.backButton,
    };
  },

  clickBackEvent(e) {
    e.preventDefault();
    this.trigger('back');
  },

  clickCloseEvent(e) {
    e.preventDefault();
    this.trigger('close');
  },

  toggleButton(enable) {
    const addMoneyBtnCollection = (
      document.getElementsByClassName('btn-add-money')
    );
    [...addMoneyBtnCollection].forEach(
      (button) => {
        button.disabled = !enable;
      },
    );
  },

  changedAmount() {
    const selectedAmount = document.getElementById('add-money-amount').value;
    const enable = selectedAmount !== '';
    this.toggleButton(enable);
  },

  /**
   User clicked the add money button on the add money popup.
   */
  async confirmAddMoneyClickEvent(e) {
    e.preventDefault();
    const amount = document.getElementById('add-money-amount').value;
    const self = this;
    if (paymentFormHandler) {
      if (!paymentFormHandler.isValid()) {
        return;
      }
      const $paymentForm = $('.payment-form');
      $paymentForm.find('#id_amount').val(amount);
      const data = await paymentFormHandler.submit();
      if (data?.added_amount) {
        this.handleAddedMoney(data.added_amount, data.new_balance, self);
      }
      if (data?.can_charge_to_default_payment_method) {
        this.disablePaymentForm();
      }
      return;
    }

    $('body').spinner(SG.spinnerSettings);
    let data = {};
    try {
      data = await this.sendAddMoneyRequest(amount);
    } catch (ex) {
      let errorMessage = '';
      const responseText = ex && ex.responseText;
      if (responseText) {
        errorMessage = Object.values(JSON.parse(responseText)).join(', ');
      }
      if (!errorMessage) {
        errorMessage = SG.userLang() === 'es'
          ? 'Error al agregar dinero' : 'Error adding money';
      }
      SG.userError(errorMessage);
      $('body').clearSpinner();
      return;
    }
    if (data.show_payment_form) {
      this.initPaymentForm();
      $('body').clearSpinner();
      return;
    }
    if (data.stripe_session_id) {
      window.initStripe();
      $.fancybox.close();
      window.stripe.redirectToCheckout({ sessionId: data.stripe_session_id })
        .then((result) => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message.
          if (result.error) {
            $('body').clearSpinner();
            SG.userError(result.error.message);
          }
        })
        .finally(() => {
          $('body').clearSpinner();
        });
    } else if (data?.added_amount) {
      this.handleAddedMoney(data.added_amount, data.new_balance, self);
    } else if (data.paymentIntent && data.paymentIntent.amount) {
      // NOT authoritative, and the actual funding could be delayed due
      // to relying on a webhook. Stripe is usually fast, tho,
      // so assume it will be there.
      const addedAmount = data.paymentIntent.amount / 100.0;
      const newBalance = parseFloat(data.starting_balance) + addedAmount;
      this.handleAddedMoney(addedAmount, newBalance, self);
    }
  },

  handleAddedMoney(addedAmount, newBalance, self) {
    const message = SG.userLang() === 'es'
      ? 'han sido agregado a tu cuenta.'
      : 'has been added to your account.';
    self.disableFundingOffer();
    $('body').clearSpinner();
    SG.userSuccess(`$${addedAmount} ${message}`);
    self.trigger('money_added', newBalance);
  },

  async sendAddMoneyRequest(amount) {
    if (!amount) {
      throw new Error();
    }

    const postData = {
      amount,
    };

    if (this.options.returnURL) {
      postData.return_url = this.options.returnURL;
    }

    const ajaxArgs = {
      url: '/api/fund/',
      contentType: 'application/json; charset=UTF-8',
      data: JSON.stringify(postData),
    };

    const result = await intentApiPost(ajaxArgs);
    return result;
  },
});
