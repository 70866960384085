/* global usaepay, CollectJS */
/* eslint max-classes-per-file: 0 prefer-object-spread: 0 */

import { BillingFormUtils } from './FormUtils';

// Aligned to payments/sources.py
const processorSourceIds = { stripe: 5, usaepay: 6, ecsuite: 7 };

class PaymentFormUtils extends BillingFormUtils {
  // Emits: actionRequired(clientSecret, successRedir)
  constructor(form = '.payment-form') {
    super(
      form,
      BillingFormUtils.ValidateNotBlank.create(['first_name', 'last_name']),
    );
    _.extend(this, Backbone.Events);

    // Pass through any actionRequired to listeners. Only supported by Stripe.
    this.responseKeys.actionRequired = ({ clientSecret, successRedir }) => {
      this.trigger('actionRequired', clientSecret, successRedir);
    };

    if (this.hasInput('accept_tos')) {
      this.customErrorFinders.accept_tos = () => (
        this.$getForm().find('.accept-tos .errors')
      );
      this.updateValidators({
        accept_tos: new BillingFormUtils.ValidateNotBlank(
          SG.userLang() === 'es'
            ? 'Toque o haga clic para aceptar'
            : 'Tap or Click to accept',
        ),
      });

      // Bind ALL tos inputs to keep them synced. Only one is real, other is
      // dummy for triggering this.
      $('.accept-tos :checkbox').change((e) => {
        // We want this to bind to element.
        $('.accept-tos :checkbox').prop('checked', e.target.checked);
      });
    }
  }
}

/**
 * Handles collecting sensitive payment info, tokenizing that info, and emitting
 * any errors encountered.
 *
 * Define: isFormComplete: if payment form is complete and ready to submit.
 * isFormEmpty: if payment form is empty. mountElement(container): to mount the
 * paymnet form to the container el. async tokenize(billingDetails): to submit
 * the payment form and return token data to matche the django form:
 * {payment_token, credit_card_last4, credit_card_expiry_date_0,
 * credit_card_expiry_date_1} date_0 is int month and date_1 is 4 digit int
 * year. TODO: rename.
 *
 * Emit: error: errors passed through from the payment form change: when the
 * payment form is changed (if supported)
 */
class Tokenizer {
  constructor(options = {}) {
    _.extend(this, Backbone.Events);

    this.paymentOptions = options;
    this.isFormComplete = false;
    this.isFormEmpty = true;
  }
}

class USAePayTokenizer extends Tokenizer {
  constructor(client, options = {}) {
    const defaultOptions = {
      style: {},
      cvvRequired: true,
    };
    super(Object.assign(defaultOptions, options));
    this.client = client;
    this.cardElement = null;
    this._paymentCard = this.client.createPaymentCardEntry();
  }

  async mountElement(container = '#usaepay-card-element') {
    // No "change" event to tell us state, so have to guess a bit. An empty
    // error message is sent on each "input" that is entered correctly, so
    // tally them and assume a min based on # required elements.
    let successMessageCount = 0;
    const { style, cvvRequired } = this.paymentOptions;
    const minSuccess = cvvRequired ? 3 : 2;
    // Only accepts an id, doesn't support selector syntax.
    const $el = $(container);
    // Note: undocumented option, "extended_response: true" returns more
    const options = { cvv_required: cvvRequired };
    this._paymentCard.generateHTML(style, options);
    this._paymentCard.addHTML($el.prop('id'));
    this._paymentCard.addEventListener('error', (message) => {
      this.trigger('error', message);
      if (!message) {
        // success
        successMessageCount += 1;
      }
      // Assume any message means the form is not empty.
      this.isFormEmpty = false;
      this.isFormComplete = successMessageCount >= minSuccess;
    });
    // TODO: is there a "ready" event for USAePay?
    return Promise.resolve();
  }

  async tokenize() {
    // By docs example, the return can be string (key) or obj (error) If use
    // options.extended_response, returns serialized JSON string.
    const result = await this.client.getPaymentKey(this._paymentCard);
    if (result.error) {
      this.trigger('error', result.error.message);
      return null;
    }
    return {
      processor_id: processorSourceIds.usaepay,
      payment_token: result,
    };
  }
}

class ECSuiteTokenizer extends Tokenizer {
  constructor(client, options = {}) {
    const isEs = SG.userLang() === 'es';
    const cardNumberTitle = isEs ? 'Número de Tarjeta' : 'Card Number';
    const cardExpTitle = isEs ? 'Fecha de Caducidad' : 'Card Expiration';
    const cvvTitle = isEs ? 'Código de Seguridad' : 'Security Code';
    const defaultOptions = {
      variant: 'inline',
      invalidCss: {
        border: 'solid 2px red',
      },
      validCss: {
        border: 'solid 2px green',
      },
      fields: {
        ccnumber: {
          title: cardNumberTitle,
          enableCardBrandPreviews: true,
          placeholder: '1234 1234 1234 1234',
        },
        ccexp: {
          title: cardExpTitle,
          placeholder: 'MM / YY',
        },
        cvv: {
          title: cvvTitle,
          placeholder: 'CVV / CVC',
        },
      },
      currency: 'USD',
    };
    super(Object.assign(defaultOptions, options));
    this.client = client;
    this.validatedFields = [];
    this.fieldStates = {};
    this.fieldNames = {
      ccnumber: cardNumberTitle,
      ccexp: cardExpTitle,
      cvv: cvvTitle,
    };
    this._tokenizePromise = null;
  }

  _onCollectValidation(field, isValid, errorMessage) {
    // Handle collect.js validation

    // Clears the form errors
    this.trigger('change');

    // Store the state
    this.fieldStates[field] = {
      isValid,
      errorMessage,
    };

    const errors = Object.keys(this.fieldStates)
      .filter((f) => !this.fieldStates[f].isValid)
      .map((f) => `${this.fieldNames[f]}: ${this.fieldStates[f].errorMessage}`);

    if (errors.length) {
      this.trigger('error', errors);
    }

    // Check if form is complete or empty
    this.isFormComplete = (
      Object.keys(this.fieldStates).length === Object.keys(this.fieldNames).length
      && errors.length === 0
    );

    this.isFormEmpty = (
      Object.keys(this.fieldStates).length === 0
      || Object.values(this.fieldStates).every(
        (f) => !f.isValid && f.errorMessage === 'Field is empty',
      )
    );
  }

  async mountElement() {
    // Promise for when the fields finished loading
    const fieldsLoaded = new Promise((loadedResolve) => {
      // Promise for when the tokenizer finishes after startPaymentRequest
      // called, in tokenize method.
      const options = {
        ...this.paymentOptions,
        callback: (result) => this.trigger('tokenized', result),
        fieldsAvailableCallback: loadedResolve,
        validationCallback: this._onCollectValidation.bind(this),
      };
      this.client.configure(options);
    });

    return fieldsLoaded;
  }

  async tokenize() {
    // Create a promise that will be resolved when
    // the "tokenized" event is triggered
    const tokenizedPromise = new Promise((resolve) => {
      this.once('tokenized', resolve);
    });

    this.client.startPaymentRequest();
    const result = await tokenizedPromise;

    return {
      processor_id: processorSourceIds.ecsuite,
      payment_token: result.token,
    };
  }
}

class PaymentForm {
  /**
   * Will create and mount the card element, linking up everything with the
   * formUtils to handle card errors, validation, etc. Call bindToForm() once
   * DOM ready, and everything will be hooked up.
   */
  constructor(
    tokenizer,
    actionRequiredHandler = null,
    formUtils = null,
    errorContainer = '#card-errors',
  ) {
    _.extend(this, Backbone.Events);
    this.tokenizer = tokenizer;
    this.actionRequiredHandler = actionRequiredHandler;
    this.formUtils = formUtils || new PaymentFormUtils();
    this.errorContainer = errorContainer;
    this._cardErrors = [];
    // Bind "this" on handlers so can access this class
    this.onSubmit = this.onSubmit.bind(this);
  }

  async bindToForm(cardContainer) {
    this.$form = this.formUtils.$getForm();
    this.$errorContainer = $(this.errorContainer);
    // Hook up all the event listeners
    this.listenTo(this.tokenizer, 'error', this.showCardErrors);
    this.listenTo(this.tokenizer, 'change', this.clearCardErrors);
    this.listenTo(this.formUtils, 'actionRequired', this.onActionRequired);
    this.listenTo(this.actionRequiredHandler, 'error', this.showCardErrors);
    await this.tokenizer.mountElement(cardContainer);
    // We replace the default FormUtils submit handler with our own TODO:
    // Decouple this.
    this.$form.off('submit.FormUtils');
    this.$form.on('submit.PaymentForm', this.onSubmit);
    this.disableAltSource = Boolean($('#disable_alt_source').val());
  }

  async onSubmit(e) {
    // Helper to allow direct binding
    e.preventDefault();
    await this.submit();
  }

  showCardErrors(messages, namespace = 'card') {
    this._cardErrors = Array.isArray(messages) ? messages : [messages];
    this.formUtils.showElErrors(this._cardErrors, this.$errorContainer, namespace);
    this.formUtils.spinnerOff();
  }

  clearCardErrors(namespace = 'card') {
    this._cardErrors = [];
    this.formUtils.clearElErrors(this.$errorContainer, namespace);
  }

  isValid(forceValidation = false) {
    // The form validation clears all errors, including any tokenizer errors.
    const formValid = this.formUtils.isValid(forceValidation);
    if (formValid && this.tokenizer.isFormComplete) {
      return true;
    }
    if (this.tokenizer.isFormEmpty) {
      // Show default "no blank" error.
      this.showCardErrors(
        new PaymentFormUtils.ValidateNotBlank().getErrors(),
      );
    } else {
      // Reset any cleared card errors. TODO: deal with this better.
      this.showCardErrors(this._cardErrors);
    }
    return false;
  }

  async onActionRequired(clientSecret, successRedir) {
    const success = await this.actionRequiredHandler.handle(clientSecret);
    if (success) {
      this.formUtils.handleResponseRedir(successRedir);
      this.formUtils.spinnerSetMessage('');
    } else {
      this.formUtils.spinnerOff();
    }
  }

  async submit() {
    // Get a payment token and then ajax the form the action url.
    if (!this.isValid(true)) {
      return null;
    }

    this.formUtils.spinnerOn();
    try {
      const billingDetails = this.formUtils.getStripeBillingDetails();
      const tokenData = await this.tokenizer.tokenize(billingDetails);
      if (!tokenData) {
        throw new Error('processorErrors');
      }
      const response = await this.formUtils.submit({ extraData: tokenData });
      if (this.formUtils.lastHandled.length === 0) {
        // Nothing expected was returned (default handlers should have handled
        // it)
        this.formUtils.showGeneralErrors([
          SG.userLang === 'es'
            ? 'Respuesta inesperada de nuestros servidores. Por favor, póngase'
              + ' en contacto con nosotros para obtener ayuda.'
            : 'Unexpected response from our servers. Please contact us for'
              + 'assistance.',
        ]);
        throw new Error('serverErrors');
      }
      return response;
    } catch (error) {
      this.formUtils.spinnerOff();
      SG.debug(error);
      // If client side looks valid, show alt option
      if (!this.disableAltSource && this.isValid()) {
        const msg = SG.userLang() === 'es'
          ? '¿Teniendo problemas? <button type="button" class="button-link'
          + ' alt-source-link" data-source="auto">Haga clic aquí para comprar'
          + ' usando nuestro procesador de pago alternativo</button > '
          : 'Having problems? <button type="button" class="button-link'
          + ' alt-source-link" data-source="auto">Click here to buy using'
          + ' our alternate payment processor</button > ';
        this.formUtils.showGeneralErrors([msg], { html: true, append: true });
      }
    }
    return null;
  }
}

async function getECSuiteClient() {
  // Ensure CollectJS is loaded before using it.
  let sanity = 70; // 7 seconds
  return new Promise((resolve, reject) => {
    if (typeof CollectJS !== 'undefined') {
      resolve(CollectJS);
      return;
    }
    const checkInterval = setInterval(() => {
      if (typeof CollectJS !== 'undefined') {
        clearInterval(checkInterval);
        resolve(CollectJS);
      }
      sanity -= 1;
      if (sanity <= 0) {
        clearInterval(checkInterval);
        reject(new Error('CollectJS failed to load'));
      }
    }, 100);
  });
}

export async function init() {
  // Note: USAePay only works with elements with an id.
  const usaepayEl = '#usaepay-card-element';
  const ecsuiteEl = '#ecsuite-card-element';
  let tokenizer;
  let cardEl;
  if ($(ecsuiteEl).length) {
    $('#credit-card-form').spinner();
    cardEl = ecsuiteEl;
    const options = {};
    try {
      const client = await getECSuiteClient();
      tokenizer = new ECSuiteTokenizer(client, options);
    } catch (error) {
      SG.debug(error);
      $('#credit-card-form').html(
        '<h1>There was a problem loading the payment form.'
        + ' Please <a href="javascript:location.reload()">reload the page</a>'
        + ' and try again, or <a href="/help/">contact us</a> for assistance.</h1 >',
      );
    }
    // Add handlers for the account update page. TODO: Fix this.
    /* eslint-disable no-new */
    // new SubscriptionUpdateFormUtils('#subscription-plan-update');
  } else if ($(usaepayEl).length) {
    const options = {
      // Style usage not documented, had to dig through source.
      // The library prefixes the key with '.payjs-', so look
      // for elements in iframe html using those classes to
      // target them (without the prefix). Can target specific ids
      // if chain from the class. Found no obvious way to target the
      // main container, though, as it only has an id
      // (no class defined in it or it's parents).
      style: {
        base: {
          fontFamily: 'Georgia, serif',
          fontSize: 'clamp(10px, 5vw, 14px)',
          height: '100vh',
        },
        'base#payjs-exp': {
          width: '22%',
        },
        'base#payjs-cvv': {
          width: '15%',
        },
        'base#payjs-cnum': {
          paddingLeft: '6px',
          width: '63%',
        },
        'base#payjs-input-icon': {
          paddingLeft: 'clamp(6px, 1vw, 15px)',
        },
      },
    };

    cardEl = usaepayEl;
    const usaepayClient = new usaepay.Client($('#id_publishable_key').val());
    tokenizer = new USAePayTokenizer(usaepayClient, options);
  }

  if (!tokenizer) {
    return null;
  }
  const paymentForm = new PaymentForm(tokenizer);
  await paymentForm.bindToForm(cardEl);

  $('#credit-card-form').clearSpinner();
  $(document).on('click', '.alt-source-link', (e) => {
    e.preventDefault();
    const ajaxArgs = {
      url: paymentForm.$form[0].dataset.altSourceUrl,
      dataType: 'json',
      data: { 'alt-source': e.target.dataset.source || 'auto' },
    };

    SG.apiPost(ajaxArgs).then((data) => {
      if (data.redir) {
        window.location.replace(data.redir);
      } else {
        const msg = SG.userLang() === 'es'
          ? 'El intento de obtener un enlace al procesador de pago'
          + ' alternativo falló.'
          : 'Attempt to get a link to alternate payment processor failed.';
        SG.userError(msg);
      }
    });
  });

  $('.payment-submit').prop('disabled', false);
  return paymentForm;
}

export default init;
