class FormUtils {
  // Some basic tools for working with django forms, ie to populate errors. if
  // noBind, will not bind form submit event and DOM not needed at init. Became
  // bigger than intended; should have prob used via backbone (or newer
  // framework).
  constructor(
    form,
    validators = {},
    {
      errorsContainer = '.errors',
      errorsULClass = 'errorlist',
      bindForm = true,
      spinnerMount = 'BODY',
      // If true, this will handle spinners
      spinnerOnSubmit = true,
      // Will not error if an input trying to validate is missing
      skipMissingInputs = true,
    } = {},
  ) {
    this.formSelector = form;
    this.validators = validators;
    this.errContainer = errorsContainer;
    // Used for both selecting existing and creating
    this.errULClass = errorsULClass;
    this.spinnerActive = false;
    this.spinnerMountSelector = spinnerMount;
    // Default to just showing as message, if no spinner
    this.spinnerSetMessage = this.handleResponseMessage;
    this.spinnerOnSubmit = spinnerOnSubmit;
    this.skipMissingInputs = skipMissingInputs;
    this.lastHandled = [];

    /**
     * To prevent default handling of any of these, delete them or set falsey.
     * Can also prevent all default handling with useDefaultResponseHandler =
     * False on submit. Add new handler types for response data as: keyInData:
     * handlerFunction(<payload data>, responseIsSuccess) {} Return truthy to
     * indicate the key was successfully handled then include in the response
     * data: { keyToMatch: <payload data> } WARNING: may need to bind "this" if
     * handler is a method (since not called with "this.<method>"). All bound by
     * default, in case overridden.
     */
    this.responseKeys = {
      formErrors: this.handleResponseFormErrors.bind(this),
      formUpdates: this.handleResponseFormUpdates.bind(this),
      redir: this.handleResponseRedir.bind(this),
      reload: this.handleResponseReload.bind(this),
      message: this.handleResponseMessage.bind(this),
      apiErrors: this.handleResponseApiErrors.bind(this),
    };
    this.scrollTopOnGeneralErrors = true;

    /**
     * Filters for getting input values during validation Only need to define
     * filters for types that don't work with a simple .val() on the $element.
     * Filters should accept $element that is result of selecting the form
     * input. Note: RADIOs may have multiple elements in $element, SELECT inputs
     * need no special filter. This is not intended to be exhaustive, add as
     * needed.
     */
    const filter = ($el, sel) => $el.filter(sel).val();
    const checkedFilter = ($el) => filter($el, ':checked');
    this.inputTypeFilters = {
      radio: checkedFilter,
      checkbox: checkedFilter,
    };
    this.errors = {};

    /**
     * Finders for finding the error container to write errors to for an input.
     * Add custom finders to customErrorFinders keyed by input name. Finders
     * should accept an input name and return a container $element.
     */
    // This is the defacto finder, that most of our forms should work with.
    this.inputErrorFinder = (name) => (
      this.$getInput(name).closest('div').find(this.errContainer)
    );
    // This is where general errors go. Uses first container found in form.
    this.generalErrorFinder = () => (
      this.$getForm().find(this.errContainer).first()
    );
    // Add finders keyed by input name.
    this.customErrorFinders = {};
    // This flag is set true when validation runs and succeeds, and is cleared
    // by clearErrors.
    this.isComplete = false;

    // Bind "this" on handlers so can access this class
    this.onSubmit = this.onSubmit.bind(this);

    if (bindForm) {
      this.bindToForm();
    }
  }

  bindToForm() {
    this.$getForm().on('submit.FormUtils', this.onSubmit);
  }

  $getSpinnerMount() {
    // To simplifyy checks, will return null if no mount element found.
    if (typeof this.$spinnerMount === 'undefined') {
      // need to init it
      this.$spinnerMount = $(this.spinnerMountSelector);
      if (this.$spinnerMount.length === 0) {
        this.$spinnerMount = null;
      }
    }
    return this.$spinnerMount;
  }

  spinnerOn(message = '', force = false) {
    const $mount = this.$getSpinnerMount();
    let activated = false;
    if ($mount) {
      if (force && this.spinnerActive) {
        this.spinnerOff();
      }
      if (!this.spinnerActive) {
        // Message spinner only works on body at this point. Fails to normal
        // site message.
        if (this.spinnerMountSelector === 'BODY') {
          this.spinnerSetMessage = $mount.messageSpinner();
        } else {
          $mount.spinner();
        }
        this.spinnerActive = true;
        activated = true;
      }
    }
    if (message) {
      this.spinnerSetMessage(message);
    }

    return activated;
  }

  spinnerOff() {
    const $mount = this.$getSpinnerMount();
    if ($mount) {
      $mount.clearSpinner();
      this.spinnerActive = false;
      return true;
    }
    return false;
  }

  updateValidators(validators) {
    // Note: airbnb recommends spread op, but not ES6
    Object.assign(this.validators, validators);
  }

  $getInput(name) {
    return this.$getForm().find(`[name="${name}"]`);
  }

  hasInput(name) {
    return this.$getInput(name).length > 0;
  }

  getInputVal(name) {
    const $el = this.$getInput(name);
    if ($el.length === 0) {
      return null;
    }
    const elType = $el.prop('type');
    const filter = this.inputTypeFilters[elType];
    const val = filter ? filter($el) : $el.val();
    return $.trim(val);
  }

  setInputVal(name, val) {
    return this.$getInput(name).val(val);
  }

  setInputVals(formUpdates) {
    // { name: value, ...}. Not tested with non-text input types.
    Object.entries(formUpdates).forEach(([name, val]) => {
      this.setInputVal(name, val);
    });
  }

  getValidatedValues() {
    const formValues = {};
    Object.keys(this.validators).forEach((name) => {
      if (!(name in this.errors)) {
        const v = this.getInputVal(name);
        if (v !== null) {
          formValues[name] = v;
        }
      }
    });
    return formValues;
  }

  validate() {
    this.clearErrors();
    Object.entries(this.validators).forEach(([name, validator]) => {
      const inputVal = this.getInputVal(name);
      if (inputVal === null) {
        if (!this.skipMissingInputs) {
          // Show as general error, since no input so likely no error div
          const error = validator.getErrors().join(', ');
          this.showInputErrors(`${name}: ${error}`);
        }
        return;
      }
      if (!validator.isValid(name, inputVal, this)) {
        this.showInputErrors(validator.getErrors(), name);
      }
    });
    this.isComplete = $.isEmptyObject(this.errors);
    return this.isComplete;
  }

  isValid(forceValidation = false) {
    // This will validate if it hasn't been yet, and return if valid/complete
    // NOTE: this will NOT validate again by default, if isComplete, even if
    // values changed since!
    if (forceValidation || !this.isComplete) {
      this.validate();
    }
    // Override for more detailed checks
    return this.isComplete;
  }

  $getForm() {
    // This allows creating early, before form might exist
    if (!this.$form) {
      this.$form = $(this.formSelector);
    }
    return this.$form;
  }

  showElErrors(
    messages,
    $errorsContainer,
    name = '',
    { html = false, append = false } = {},
  ) {
    // Names will be collated in this.errors. Should be the input name, when for
    // an input. Use blank name for the top/general errors. Blank values will
    // REMOVE errors.
    let $list = $errorsContainer.find(`.${this.errULClass}`);
    // Create new list if none found or not appending
    if ($list.length === 0 || !append) {
      // In case append...
      $list.remove();
      $list = $(`<ul class="${this.errULClass}"></ul>`).appendTo(
        $errorsContainer,
      );
    }
    if (messages.length === 0) {
      if (!append) {
        delete this.errors[name];
      }
      return;
    }
    this.errors[name] = true;
    messages.forEach((message) => {
      // Prevents rendering html unless set to allow.
      if (html) {
        $list.append($('<li></li>').html(message));
      } else {
        $list.append($('<li></li>').text(message));
      }
    });
  }

  showInputErrors(messages, name = '', options = {}) {
    // Show errors for an input (input name or a jQuery object wrapped input).
    // If name is blank/empty/"__all__" (special Django value), uses the
    // "generic" error at top of form.
    let $errorsContainer;
    const n = !name || name === '__all__' ? '' : name;

    if (n) {
      const finder = this.customErrorFinders[n] || this.inputErrorFinder;
      $errorsContainer = finder(n);
    } else {
      $errorsContainer = this.generalErrorFinder();
      this.scrollTop();
    }

    this.showElErrors(messages, $errorsContainer, n, options);
  }

  scrollTop(ignoreExistingErrors = false) {
    // Don't scroll if there are field errors already added, unless
    // ignoreExistingErrors
    if (
      this.scrollTopOnGeneralErrors
      && (ignoreExistingErrors
        || Object.keys(this.errors).filter(
          (key) => key !== '' && key !== '__all__',
        ).length === 0)
    ) {
      window.scrollTo(0, 0);
      return true;
    }
    return false;
  }

  showGeneralErrors(messages, options = {}) {
    this.showInputErrors(messages, '', options);
  }

  showErrors(formMessages, options = {}) {
    // This handles the results as formatted by form.errors: {<input>:
    // ['<message>', ...], ...} Temp disable scrolling, check for scroll at end
    const curScroll = this.scrollTopOnGeneralErrors;
    this.scrollTopOnGeneralErrors = false;
    let generalError = false;
    Object.entries(formMessages).forEach(([input, messages]) => {
      generalError = generalError || input === '' || input === '__all__';
      this.showInputErrors(messages, input, options);
    });
    this.scrollTopOnGeneralErrors = curScroll;
    if (generalError) {
      this.scrollTop();
    }
  }

  clearInputErrors(name = '') {
    this.showInputErrors('', name);
  }

  clearElErrors($errorsContainer, name = '') {
    this.showElErrors('', $errorsContainer, name);
  }

  clearGeneralErrors() {
    this.showGeneralErrors('');
  }

  clearErrors() {
    this.isComplete = false;
    this.errors = {};
    this.$getForm().find(this.errContainer).empty();
  }

  // eslint-disable-next-line class-methods-use-this
  handleResponseRedir(redir) {
    // 'url' || { url: 'url', replace: true }
    if (redir) {
      let url;
      let replace = false;
      if (typeof redir === 'object') {
        ({ url, replace = false } = redir);
      } else {
        url = redir;
      }
      url = $.trim(url);
      if (url) {
        if (replace) {
          window.location.replace(url);
        } else {
          window.location.assign(url);
        }
        // At this point, redir is happening so prob won't get past here...
        SG.debug(`Form AJAX response has redirect to ${url}`);
        return true;
      }
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  handleResponseReload(force) {
    // true/false should force get (aka, if use cache)
    if (force || force === false) {
      window.location.reload(force);
      return true;
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  handleResponseMessage(message) {
    // 'text' || { text: 'text', type: 'type' }
    if (message) {
      let text;
      let handler;
      if (typeof message === 'object') {
        text = $.trim(message.text);
        if (message.type === 'spinner') {
          // Special case, appends. Empty text clears existing message
          this.spinnerSetMessage(text);
          return true;
        }
        this.spinnerOff();
        switch (message.type) {
          case 'success':
            handler = SG.userSuccess;
            break;
          case 'error':
            handler = SG.userError;
            break;
          case 'warning':
            handler = SG.userWarning;
            break;
          default:
            handler = SG.userMessage;
        }
      } else {
        this.spinnerOff();
        handler = SG.userMessage;
        text = $.trim(message);
      }
      if (text) {
        handler(text);
        return true;
      }
    }
    return false;
  }

  handleResponseFormErrors(errors) {
    this.spinnerOff();
    this.showErrors(errors);
    return true;
  }

  handleResponseFormUpdates(updates) {
    this.setInputVals(updates);
    return true;
  }

  handleResponseApiErrors(errors) {
    this.spinnerOff();
    this.showInputErrors(errors);
    return true;
  }

  defaultResponseHandler(data, isSuccess = null) {
    // Handle common responses. Returns array of keys handled.
    const handled = [];

    Object.entries(this.responseKeys).forEach(([key, handler]) => {
      if (
        handler
        && typeof data[key] !== 'undefined'
        && handler(data[key], isSuccess)
      ) {
        handled.push(key);
      }
    });

    return handled;
  }

  onSubmit(e) {
    // Helper to allow direct binding
    e.preventDefault();
    this.submit();
  }

  getSerializedForm() {
    return this.$getForm().serialize();
  }

  submit({
    url = null,
    extraData = null,
    useDefaultResponseHandler = true,
  } = {}) {
    // TODO: this is a mess, consider refactor as async/await
    return (
      new Promise((resolve, reject) => {
        this.clearErrors();

        if (!this.isValid()) {
          throw new Error({ type: 'clientErrors' });
        }

        if (this.spinnerOnSubmit) {
          this.spinnerOn();
        }

        let formData = this.getSerializedForm();
        if (extraData) {
          formData += `&${$.param(extraData)}`;
        }
        const ajaxArgs = {
          url: url || this.$getForm().prop('action'),
          dataType: 'json',
          data: formData,
          async: false,
        };

        // This is jquery promise, so using reject (vs throw) to get around
        // jquery exception handling
        SG.apiPost(ajaxArgs)
          .then((data) => {
            this.lastHandled = [];
            if (useDefaultResponseHandler) {
              this.lastHandled = this.defaultResponseHandler(data, true);
            }
            return resolve(data);
          })
          .fail(($xhr, txtStatus) => {
            const spanish = SG.userLang() === 'es';
            const miscError = spanish
              ? 'Ocurrió un error inesperado. Vuelva a intentarlo o'
              + ' contáctenos en help@suicidegirls.com para obtener ayuda.'
              : 'An unexpected error occurred. Please try again or contact us'
              + ' at help@suicidegirls.com for assistance';
            // Failed: may have redir, form errors, or txtStatus (ie: network
            // issues/timeout)
            let data = null;
            try {
              data = $xhr.responseJSON || JSON.parse($xhr.responseText);
            } catch (e) {
              SG.debug(
                `Form AJAX submit response JSON could not be parsed: ${e}`,
              );
            }
            SG.debug('Form AJAX submit failed');
            SG.debug(data);
            this.lastHandled = [];
            if (!useDefaultResponseHandler) {
              return reject(new Error('serverErrors'));
            }
            if (data) {
              this.lastHandled = this.defaultResponseHandler(data, false);
              if (this.lastHandled.length === 0) {
                this.showInputErrors([miscError]);
              }
            } else if ($xhr.statusCode === 503) {
              const msg = spanish
                ? 'Nuestros servidores no están disponibles actualmente,'
                + ' probablemente debido al mantenimiento. Espere unos minutos'
                + ' y vuelva a cargar la página para volver a intentarlo.'
                : 'Our servers are currently unavailable, likely due to'
                + ' maintenance. Please wait a few minutes and reload the page'
                + ' to try again.';
              if (this.handleResponseApiErrors([msg], false)) {
                this.lastHandled.push('apiErrors');
              }
            } else {
              SG.debug(`Form submit failed with status ${txtStatus}`);
              if (this.handleResponseApiErrors([miscError], false)) {
                this.lastHandled.push('apiErrors');
              }
            }
            return reject(new Error('serverErrors'));
          });
      })
        // Make sure spinner turned off (handlers should aleady turn off if
        // appropriate). TODO: Once finally available, replace these.
        .then((data) => {
          if (this.lastHandled.length === 0) {
            this.spinnerOff();
          }
          return data;
        })
        .catch((error) => {
          if (this.lastHandled.length === 0) {
            this.spinnerOff();
          }
          SG.debug(error);
          throw error;
        })
    );
  }
}

// Rudimentary form validation, for use with formUtils
FormUtils.InputValidator = class {
  constructor(errorMessage) {
    this.message = errorMessage;
  }

  static create(names, errorMessage) {
    const instance = new this(errorMessage);
    const validators = {};
    names.forEach((name) => {
      validators[name] = instance;
    });
    return validators;
  }

  /* eslint-disable class-methods-use-this, no-unused-vars */
  isValid(name, value, formUtils) {
    // Will be passed input name and value currently being checked, plus a
    // reference to the formUtils calling it. OVERRIDE THIS. This simply
    // verifies it's not empty.
    return value !== '';
  }
  /* eslint-enable class-methods-use-this, no-unused-vars */

  getErrors() {
    return [this.message];
  }
};

FormUtils.ValidateNotBlank = class extends FormUtils.InputValidator {
  constructor(
    errorMessage = SG.userLang() === 'es'
      ? 'Esto es requerido'
      : 'This is required',
  ) {
    super(errorMessage);
  }
};

FormUtils.ValidateMatches = class extends FormUtils.InputValidator {
  constructor(
    matchToName,
    errorMessage = SG.userLang() === 'es'
      ? 'Los valores no coinciden'
      : "Values don't match",
  ) {
    super(errorMessage);
    this.matchToName = matchToName;
  }

  isValid(name, value, formUtils) {
    return value === formUtils.getInputVal(this.matchToName);
  }
};

class AddressFormUtils extends FormUtils {
  constructor(form, validators, namePrefix = '', ...args) {
    const defaultValidators = FormUtils.ValidateNotBlank.create(
      [
        'address',
        'city',
        'state_or_province', // Not all Countries have one
        'zip_or_postal_code',
        'country',
      ].map((name) => `${namePrefix}${name}`),
    );
    super(form, defaultValidators, ...args);
    this.updateValidators(validators);
    this.namePrefix = namePrefix;
    // The errors for country select is a sibling of the container div
    this.customErrorFinders[`${namePrefix}country`] = (name) => (
      this.$getInput(name).closest('div').next(this.errContainer)
    );
  }

  getNormalizedValidatedValues() {
    // Removes prefixes from names
    const normalized = {};
    Object.entries(this.getValidatedValues()).forEach(([name, value]) => {
      if (name.startsWith(this.namePrefix)) {
        normalized[name.replace(this.namePrefix, '')] = value;
      } else {
        normalized[name] = value;
      }
    });
    return normalized;
  }

  $getInput(name) {
    const $input = super.$getInput(name);
    if (!this.namePrefix || $input.length > 0) {
      return $input;
    }
    // Billing forms use prefixes but the prefix isn't used in the error keys.
    // As a workaround, if a prefix is set then check for input with that prefix
    // as well.
    return this.$getForm().find(`[name="${this.namePrefix}${name}"]`);
  }

  getStripeBillingDetails() {
    // Returns "owner" data
    const vals = this.getNormalizedValidatedValues();
    return {
      name: `${vals.first_name} ${vals.last_name}`,
    };
  }
}

class BillingFormUtils extends AddressFormUtils {
  constructor(form, validators, ...args) {
    super(form, validators, 'billing_', ...args);
  }
}

class SubscriptionUpdateFormUtils extends FormUtils {
  constructor(form, validators, ...args) {
    super(form, validators, 'billing_', ...args);
    this.responseKeys.nextBillDate = this.handleNextBillDate.bind(this);
    this.responseKeys.planChoices = this.handlePlanChoices.bind(this);
  }

  handleNextBillDate(billDate) {
    this.$getForm().find('#next-bill-date').text(billDate);
  }

  handlePlanChoices(choices) {
    if (!choices) {
      return;
    }

    // Passed as array of arrays (from choices tuple)
    choices.forEach(([xcode, display]) => {
      // Django wraps the input in the label tag, with the label text.
      // Makes replacing just the text is more difficult than it needs to be.
      this.$getForm()
        .find(`input[value="${xcode}"]`)
        .parent('label')
        .contents()
        .last()
        .replaceWith(display);
    });

    // choices is only passed when changed plans, so cannot be legacy.
    // Hide it if exists.
    $('.legacy-plan-warning').hide();
  }
}

export { BillingFormUtils, FormUtils, SubscriptionUpdateFormUtils };
