import SearchOverlayView from './SearchOverlayView';
import './search.less';

/**
 Determines whether or not this key press event should be intercepted
 for the search feature.

 @param {object} e - JQuery event data.
*/
function isInterceptableKeyPressEvent(e) {
  if (e.altKey || e.ctrlKey || e.metaKey) {
    return false;
  }
  const chars = 'abcdefghijklmnopqrstuvwxyz1234567890';
  return chars.includes(e.key.toLowerCase());
}

/**
 Intercepts key press events and loads search dialog if the
 cursor is not currently focused on a text input and gallery
 isn't open.

 @param {object} e - JQuery event data.
*/
function keyPressEvent(e, search) {
  if (!isInterceptableKeyPressEvent(e)) {
    return;
  }

  if ($('#gallery').is(':visible')) {
    return;
  }

  const activeTag = $(document.activeElement).prop('tagName');

  if (['INPUT', 'SELECT', 'TEXTAREA'].includes(activeTag)) {
    return;
  }

  const $activeElement = $(document.activeElement);

  if (
    $activeElement.attr('contenteditable') === ''
    || $activeElement.attr('contenteditable') === 'true'
    || $activeElement.hasClass('redactor_editor')
  ) {
    return;
  }

  if (
    window.location.pathname === '/join/'
    || window.location.pathname === '/join/payment/'
  ) {
    return;
  }

  e.preventDefault();
  const key = String.fromCharCode(e.which);
  search.show(key);
}

export function init() {
  $(() => {
    // Load search overlay for any keypress not focused on a form input
    const search = new SearchOverlayView({ el: '#search-form' });
    $(document).on('keypress', (e) => {
      keyPressEvent(e, search);
    });

    // Search binding
    $('#search-button').on('click', (e) => {
      e.preventDefault();
      search.show();
    });
  });
}

export default init;
