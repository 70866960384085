import Handlebars from 'handlebars';

function showLikedUsers(appName, object, objectid) {
  $.fancybox({
    href: `/vote/liked-users/${appName}/${object}/${objectid}/`,
    type: 'ajax',
    fitToView: false,
    closeClick: false,
    openEffect: 'none',
    closeEffect: 'none',
    afterLoad() {
      // eslint-disable-next-line
      this.title = `<a href="javascript:;" class="icon-close" onclick="$.fancybox.close();"></a><span class="adv-search icon-heart">${this.title}</span><br clear="all">`;
    },
    closeBtn: false,
    helpers: {
      title: {
        type: 'inside',
        position: 'top',
      },
    },
    title: 'PEOPLE WHO LIKE THIS',
    padding: 0,
    margin: 10,
    width: 360,
  }); // fancybox
}

$(document).on('click', '#load-more-popup', (e) => {
  e.preventDefault();
  SG.apiGet($.fancybox.current.href).done(() => {
    // need to write load more code
  });
});

function compileLike(score, direction, appName, objName, objId, handlerName) {
  const scoreAsPercent = (
    (typeof score === 'string')
    && score[score.length - 1] === '%'
  );
  let likeData = {};
  if (direction === 'up') {
    likeData = {
      direction: 'clear',
      text: 'Unlike',
      class: 'active',
    };
  } else {
    likeData = {
      direction: 'up',
      text: 'Unlike',
      class: 'no-active',
    };
  }

  likeData.score = score;
  likeData.object_id = objId;
  likeData.app_name = appName;
  likeData.object_name = objName;
  likeData.show_percent = scoreAsPercent;
  likeData.handler_name = handlerName;

  const source = $('#like_template').html();
  const template = Handlebars.compile(source);
  return template(likeData);
}

function albumVote(self, args) {
  if (args.length < 3) return;
  const [
    objId,
    direction,
    data,
    _showPercent,
    appName,
    objName,
    handlerName,
  ] = args;
  let showPercent = _showPercent;
  const blogSelector = $(`#album-content-${objId}`);
  const youLike = blogSelector.find('.youLike[object=album]').first();

  if ($(self).closest('#gallery').length > 0) {
    showPercent = false;
  }
  let score = '';
  if (showPercent) {
    score = `${data.score.percent}%`;
  } else {
    score = data.score.positive_votes;
  }

  if ($(this).closest('#gallery').length < 1) {
    youLike.text(score);
  }

  if ($(self).closest('#gallery').length > 0) {
    score = '';
  }

  const compiledWithData = compileLike(
    score, direction, appName, objName, objId, handlerName,
  );

  // If on the album page
  if (
    $(self).hasClass('like-photoset')
    || $(self).hasClass('not-like-photoset')
  ) {
    youLike.replaceWith(compiledWithData);
  } else {
    $(self).replaceWith(compiledWithData);
  }

  if (direction === 'up') {
    $('#like-photoset').attr('checked', true);
    $('.like-photoset').attr('direction', 'clear');
    $('#not-like-photoset').attr('checked', false);
    $('.not-like-photoset').attr('direction', 'down');
  } else if (direction === 'down') {
    $('#like-photoset').attr('checked', false);
    $('.like-photoset').attr('direction', 'up');
    $('#not-like-photoset').attr('checked', true);
    $('.not-like-photoset').attr('direction', 'clear');
  } else {
    $('#like-photoset').attr('checked', false);
    $('.like-photoset').attr('direction', 'up');
    $('#not-like-photoset').attr('checked', false);
    $('.not-like-photoset').attr('direction', 'down');
  }
}

function testimonialVote(self, args) {
  // TODO: This should be more DRYer so this can easily be extended.
  if (args.length < 3) return;
  const [postId, direction, data] = args;
  const testimonialSelector = $(`#testimonial-content-${postId}`);
  const youLike = testimonialSelector.find('.youLike[object=testimonial]');
  let likeData = {};
  if (direction === 'up') {
    likeData = {
      direction: 'clear',
      text: 'Unlike',
      class: 'active',
    };
  } else {
    likeData = {
      direction: 'up',
      text: 'Unlike',
      class: 'no-active',
    };
  }
  youLike.text(data.score.num_votes);
  likeData.score = data.score.num_votes;
  likeData.object_id = postId;
  likeData.app_name = 'testimonials';
  likeData.object_name = 'testimonial';
  const source = $('#like_template').html();
  const template = Handlebars.compile(source);
  const compiledWithData = template(likeData);
  youLike.replaceWith(compiledWithData);
}

// Works the same as testimonials above for now
function tipVote(self, args) {
  if (args.length < 3) return;
  const [postId, direction, data] = args;
  const tipSelector = $(`#tip-content-${postId}`);
  let likeData = {};

  if (direction === 'up') {
    likeData = {
      direction: 'clear',
      text: 'Unlike',
      class: 'active',
    };
  } else {
    likeData = {
      direction: 'up',
      text: 'Unlike',
      class: 'no-active',
    };
  }

  tipSelector.find('.youLike[object=testimonial]').text(data.score.num_votes);
  likeData.score = data.score.num_votes;
  likeData.object_id = postId;
  likeData.app_name = 'tipping';
  likeData.object_name = 'tip';
  const source = $('#like_template').html();
  const template = Handlebars.compile(source);
  const compiledWithData = template(likeData);
  tipSelector.find('.youLike[object=tip]').replaceWith(compiledWithData);
}

function threadVote(self, args) {
  if (args.length < 3) return;
  const [id, direction, data] = args;
  const selector = $(`.group.thread[data-thread-id=${id}]`);
  const youLike = selector.find('.youLike[object=thread]');

  let likeData = {};

  if (direction === 'up') {
    likeData = {
      direction: 'clear',
      text: 'Unlike',
      class: 'active',
    };
  } else {
    likeData = {
      direction: 'up',
      text: 'Unlike',
      class: 'no-active',
    };
  }

  youLike.text(data.score.num_votes);

  likeData.score = data.score.num_votes;
  likeData.object_id = id;
  likeData.app_name = 'groups';
  likeData.object_name = 'thread';

  const source = $('#like_template').html();
  const template = Handlebars.compile(source);
  const compiledWithData = template(likeData);
  youLike.replaceWith(compiledWithData);
}

function commentVote(self, args) {
  if (args.length < 3) return;
  const [id, direction, data] = args;
  const commentSelector = $(`.comment[data-comment-id=${id}]`);
  let likeData = {};
  if (direction === 'up') {
    likeData = {
      direction: 'clear',
      text: 'Unlike',
      class: 'active',
    };
  } else {
    likeData = {
      direction: 'up',
      text: 'Unlike',
      class: 'no-active',
    };
  }
  commentSelector.find('.youLike').text(data.score.num_votes);

  likeData.score = data.score.num_votes ? data.score.num_votes : '';
  likeData.object_id = id;
  likeData.app_name = 'sgcomments';
  likeData.object_name = 'comment';

  const source = $('#like_template').html();
  const template = Handlebars.compile(source);
  const compiledWithData = template(likeData);
  commentSelector.find('.youLike').replaceWith(compiledWithData);
}

function blogpostVote(self, args) {
  if (args.length < 3) return;
  const [id, direction, data] = args;
  const blogSelector = $(`#post-content-${id}`);
  let likeData;

  if (direction === 'up') {
    likeData = {
      direction: 'clear',
      text: 'Unlike',
      class: 'active',
    };
  } else {
    likeData = {
      direction: 'up',
      text: 'Unlike',
      class: 'no-active',
    };
  }

  blogSelector.find('.youLike[object=blogpost]').text(data.score.num_votes);

  likeData.score = data.score.num_votes;
  likeData.object_id = id;
  likeData.app_name = 'blog';
  likeData.object_name = 'blogpost';

  const source = $('#like_template').html();
  const template = Handlebars.compile(source);
  const compiledWithData = template(likeData);
  blogSelector.find('.youLike[object=blogpost]').replaceWith(compiledWithData);
}

function videoVote(self, args) {
  if (args.length < 3) return;
  const [id, direction, data, , appName, objName, handlerName] = args;
  const blogSelector = $(`#video-content-${id}`);
  blogSelector.find('.youLike[object=video]').text(data.score.num_votes);
  const compiledWithData = compileLike(
    data.score.num_votes, direction, appName, objName, id, handlerName,
  );
  blogSelector.find('.youLike[object=video]').replaceWith(compiledWithData);
}

const functionMap = {
  album: albumVote,
  blogpost: blogpostVote,
  comment: commentVote,
  tip: tipVote,
  testimonial: testimonialVote,
  thread: threadVote,
  video: videoVote,
};

export function init() {
  $(document).ready(() => {
    $(document).on('click', '.like', function like(event) {
      event.stopPropagation();
      const appName = $(this).attr('appname');
      const objectid = $(this).attr('objectid');
      const object = $(this).attr('object');
      const direction = $(this).attr('direction');
      const showPercent = $(this).hasClass('like-percent');
      const handlerName = $(this).attr('handlername') || object;
      if ($(this).hasClass('showVotes')) {
        showLikedUsers(appName, object, objectid);
      } else {
        $.ajax({
          type: 'POST',
          url: `/vote/${appName}/${object}/${objectid}/${direction}/`,
          context: this,
          contentType: 'application/json; charset=utf-8',
          xhrFields: {
            withCredentials: true,
          },
          success(data) {
            if (data.success) {
              const func = functionMap[handlerName];
              if (func) {
                func(this, [
                  objectid,
                  direction,
                  data,
                  showPercent,
                  appName,
                  object,
                  handlerName,
                ]);
              }
            }
          },
          error(jqXHR) {
            switch (jqXHR.status) {
              case 0:
                SG.userError('Network connection problem');
                break;
              case 401:
                SG.userError('You are not logged in');
                break;
              case 403:
                SG.userError('You have been blocked by this user');
                break;
              case 500:
                SG.userError('Server error');
                break;
              case 503:
                SG.userError('Site is in maintenance mode');
                break;
              default:
                SG.userError('Error liking content');
            }
          },
        });
      }
      return false;
    });
  });
}

export default init;
