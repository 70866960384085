export function init() {
  $(document).ready(() => {
    $('.twitter-share').click(function _(event) {
      event.preventDefault();
      const width = 575;
      const height = 400;
      const left = ($(window).width() - width) / 2;
      const top = ($(window).height() - height) / 2;
      const url = this.href;
      const opts = (
        `status=1,width=${width},height=${height},top=${top},left=${left}`
      );

      window.open(url, 'twitter', opts);
      return false;
    });

    $('.facebook-share').click(function _(event) {
      const facebookAppId = $(this).attr('data-facebook-id');
      let facebookPictureUrl = $(this).attr('data-picture-url');
      const name = $(this).attr('data-name');
      const description = $(this).attr('data-description');
      if (facebookPictureUrl === null) {
        // eslint-disable-next-line max-len
        facebookPictureUrl = 'http://img.suicidegirls.com/media/members/4/03/528034/230224/4800691_attach.jpg';
      }
      event.preventDefault();
      // TODO: get SG's appId
      window.FB.init({ appId: facebookAppId, status: true, cookie: true });
      const obj = {
        method: 'feed',
        name: `Suicide Girls: ${name}`,
        link: $(this).attr('href'),
        picture: facebookPictureUrl, // temp default image
        description,
      };

      window.FB.ui(obj);
    });
  });
}

export default init;
