import EditTestimonialView from './EditTestimonialView';

export function init() {
  $(() => {
    // Load the edit testimonial view on the user profile pages
    if ($('#new_testimonial').length) {
      // eslint-disable-next-line no-new
      new EditTestimonialView({ el: $('#new_testimonial') });
    }
  });
}

export default init;
